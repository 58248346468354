import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import IntroArea from "../containers/trabaja-con-nosotros/intro-area"
import BeneficiosArea from "../containers/trabaja-con-nosotros/beneficios-area"
import PostulanteFormArea from "../containers/trabaja-con-nosotros/postulante-form-area"
import TalentoCta from "../containers/trabaja-con-nosotros/talento-cta"

const TrabajaConNosotrosPage = ({ data, pageContext, location }) => {
  const [showForm, setShowForm] = useState(false)
  return (
    <Layout location={location}>
      <Seo title="Trabaja con nosotros" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Trabaja con nosotros"
          banner={data}
        />
        {showForm ? <PostulanteFormArea /> : null}
        <IntroArea showForm={showForm} setShowForm={setShowForm} />
        <BeneficiosArea />

        <TalentoCta showForm={showForm} setShowForm={setShowForm} />
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "trabaja-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 351
          quality: 100
          width: 1366
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
        )
      }
    }
  }
`

export default TrabajaConNosotrosPage
