import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Button from "../../../components/ui/button"
import { StyledSection, StyledHeading, StyledBG, ButtonWrap } from "./style"

const TalentoCta = ({ setShowForm, showForm }) => {
  const isBrowser = typeof window !== "undefined"

  const toggle = () => {
    setShowForm(!showForm)
    if (isBrowser) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }
  return (
    <StyledSection>
      <StyledBG>
        <StaticImage src="../../../assets/images/cta-bg.png" alt="CTA BG" />
      </StyledBG>
      <Container>
        <Row>
          <Col xl={8} lg={7}>
            <StyledHeading>
              Buscamos talento, ¿te unes a Mercantil?
            </StyledHeading>
            <span>
              En Mercantil nos encanta lo que hacemos y lo transmitiremos día a
              día. Postula a nuestras vacantes.
              <br />
              <br />
              ¡Nos encantaría conocerte!
            </span>
            <br />
            <ButtonWrap>
              <Button onClick={toggle}>Postula aquí</Button>
            </ButtonWrap>
          </Col>
          <Col xl={4} lg={5} className="text-center">
            <StaticImage
              src="../../../assets/images/trabajador.png"
              alt="Trabajador Mercantil"
            />
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}

export default TalentoCta
