import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import SectionTitle from "../../../components/ui/section-title"
import BoxLargeImage from "../../../components/box-large-image/layout-one"
import { SectionWrap } from "./section.style"

const BoxSection = ({ sectionTitleStyle, linkStyle, sectionBottomStyle }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicDivision(sort: { fields: data___ordern, order: ASC }) {
        edges {
          node {
            data {
              nombre {
                text
              }
              foto {
                gatsbyImageData
              }
              descripcion {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)
  const divisiones = data.allPrismicDivision.edges
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle
              {...sectionTitleStyle}
              title="Contamos con cuatro divisiones soportadas en una gestión eficiente "
              subtitle="y un equipo especializado"
            />
          </Col>
        </Row>
        <Row className="gutter-xl-30">
          {divisiones.map(division => {
            return (
              <Col
                lg={3}
                md={3}
                className="box-item"
                key={`box-image-${division.node.uid}`}
              >
                <BoxLargeImage
                  title={division.node.data.nombre.text}
                  path={`/${division.node.uid}`}
                  imageSrc={division.node.data.foto}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </SectionWrap>
  )
}

BoxSection.propTypes = {
  sectionTitleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  sectionBottomStyle: PropTypes.object,
}

BoxSection.defaultProps = {
  sectionTitleStyle: {
    layout: "2",
    responsive: {
      medium: {
        mb: "17px",
      },
    },
  },
  linkStyle: {
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
  sectionBottomStyle: {
    mt: "40px",
    align: "center",
  },
}

export default BoxSection
