import styled, { css } from "styled-components"
import { device } from "../../../theme"

export const LinkWrapper = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 152px;
  right: 31px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  .whatsapp-icon {
    margin-top: 16px;
    color: #fff;
  }
  ${props =>
    props.responsive &&
    css`
      @media ${device.medium} {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
        .whatsapp-icon {
          margin-top: 10px;
        }
      }
    `}
`
