import styled from "styled-components"

export const SectionWrap = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`

export const AliadoLogoWrap = styled.div`
  align-self: center;
  text-align: center;
  position: relative;
`
