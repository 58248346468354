import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import SectionTitle from "../../../components/ui/section-title"
import ConsultForm from "../../../components/forms/consult-form"
import Heading from "../../../components/ui/heading"
import Anchor from "../../../components/ui/anchor"
import LocationButton from "../../../components/ui/location-button"
import { ContactWrapper, ContactInfoBox } from "./contact-area.style"

const ContactSection = ({ sectionTitleStyle, conactInfoStyles }) => {
  const { titleStyle, phoneStyle, phoneAnchorStyle, btnStyle } =
    conactInfoStyles
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            fijo
            celular
          }
        }
      }
    }
  `)
  const { fijo, celular } = data.site.siteMetadata.contact

  return (
    <ContactWrapper>
      <Container>
        <Row>
          <Col lg={6}>
            <SectionTitle
              {...sectionTitleStyle}
              title="Escríbenos"
              subtitle="Si desea información sobre nuestros productos, escríbenos a ventas@mercantil.com.pe
              o complete el siguiente formulario."
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <ConsultForm />
          </Col>
          <Col lg={5} ml="auto">
            <ContactInfoBox>
              <Heading {...titleStyle}>
                productos y servicios de calidad
              </Heading>
              <Heading {...phoneStyle}>
                <Anchor path={`tel:${fijo}`} {...phoneAnchorStyle}>
                  {fijo}
                </Anchor>
              </Heading>
              <Heading {...phoneStyle}>
                <Anchor path={`tel:${celular}`} {...phoneAnchorStyle}>
                  {celular}
                </Anchor>
              </Heading>
              <LocationButton {...btnStyle} />
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </ContactWrapper>
  )
}

ContactSection.propTypes = {
  sectionTitleStyle: PropTypes.object,
  conactInfoStyles: PropTypes.object,
}

ContactSection.defaultProps = {
  sectionTitleStyle: {
    mb: "53px",
    layout: 2,
    align: "left",
    responsive: {
      small: {
        mb: "30px",
      },
    },
  },
  conactInfoStyles: {
    titleStyle: {
      as: "h6",
      mb: "30px",
      fontSize: "15px",
      letterspacing: "2px",
      texttransform: "uppercase",
      fontweight: 500,
    },
    phoneStyle: {
      as: "h3",
      mb: "20px",
    },
    phoneAnchorStyle: {
      color: "#002fa6",
      hover: {
        layout: 2,
        color: "#002fa6",
      },
    },
    btnStyle: {
      mt: "20px",
    },
  },
}

export default ContactSection
