import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import BoxLargeImage from "../../../components/box-large-image/layout-three"
import { SectionWrap } from "./section.style"

const BoxSection = ({ sectionTitleStyle, linkStyle, sectionBottomStyle }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicBeneficio {
        edges {
          node {
            data {
              titulo {
                text
              }
              foto {
                gatsbyImageData(width: 150)
              }
            }
          }
        }
      }
    }
  `)
  const beneficios = data.allPrismicBeneficio.edges
  return (
    <SectionWrap>
      <Container>
        <Row className="gutter-xl-30">
          {beneficios.map(beneficio => {
            return (
              <Col
                lg={4}
                md={4}
                className="box-item"
                key={`box-image-${beneficio.node.data.titulo.text}`}
              >
                <BoxLargeImage
                  title={beneficio.node.data.titulo.text}
                  imageSrc={beneficio.node.data.foto}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default BoxSection
