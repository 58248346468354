import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Anchor from "../../ui/anchor"
import Text from "../../ui/text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Zoom from "react-reveal/Zoom"
import ProductoFallback from "../../../assets/images/producto-fallback.jpg"
import {
  ProductoWrapper,
  ProductoMedia,
  ProductoThumb,
  ProductoInfo,
  ProductoHeader,
  ProductoTitle,
  ProductoExcerpt,
} from "./producto.style"

const ProductoBox = ({ content, ...restProps }) => {
  const { nombre, marca, foto, division, categoria, subcategoria } =
    content.data
  const slug = content.uid
  const { wrapStyle } = restProps
  const link = subcategoria.document
    ? `/${division.document.uid}/${categoria.document.uid}/${subcategoria.document.uid}/${slug}`
    : `/${division.document.uid}/${categoria.document.uid}/${slug}`
  let imageBox
  if (foto.gatsbyImageData) {
    imageBox = <GatsbyImage image={getImage(foto)} alt={nombre.text} />
  } else {
    imageBox = (
      <img
        src={ProductoFallback}
        alt={nombre.text}
        width="232px"
        height="232px"
      />
    )
  }
  return (
    <Fragment>
      <ProductoWrapper {...wrapStyle}>
        <ProductoMedia>
          <ProductoThumb>
            <Anchor path={link}>
              <Zoom>{imageBox}</Zoom>
            </Anchor>
          </ProductoThumb>
        </ProductoMedia>
        <ProductoInfo>
          <ProductoHeader>
            {nombre && (
              <ProductoTitle>
                <Anchor path={link}>{nombre.text}</Anchor>
              </ProductoTitle>
            )}
          </ProductoHeader>
          {marca.document && (
            <ProductoExcerpt>
              <Anchor path={`/marca/${marca.document.data.uid}`}>
                <Text>{marca.document.data.nombre.text}</Text>
              </Anchor>
            </ProductoExcerpt>
          )}
        </ProductoInfo>
      </ProductoWrapper>
    </Fragment>
  )
}

ProductoBox.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  date: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  excerpt: PropTypes.string,
  format: PropTypes.string,
}

export default ProductoBox
