import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import SectionTitle from "../../../components/ui/section-title"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import Paso1 from "../../../assets/images/icons/paso-1.png"
import Paso2 from "../../../assets/images/icons/paso-2.png"
import Paso3 from "../../../assets/images/icons/paso-3.png"
import { PasosAreaWrapper } from "./pasos-area.style"

const PasosArea = ({ headingStyle }) => {
  return (
    <PasosAreaWrapper>
      <Container mt="90px" mb="90px">
        <Row>
          <Col mt="60px">
            <SectionTitle
              layout="2"
              mb="60px"
              title="Carrito de Compras"
              subtitle="Productos"
            />
          </Col>
        </Row>
        <Row align="center">
          <Col lg={4}>
            <Heading {...headingStyle}>Paso 1</Heading>
            <img src={Paso1} alt="Paso1" />
            <Text>Elige los productos y envia tu pedido</Text>
          </Col>
          <Col lg={4}>
            <Heading {...headingStyle}>Paso 2</Heading>
            <img src={Paso2} alt="Paso2" />
            <Text>Te enviamos la proforma de tu pedido</Text>
          </Col>
          <Col lg={4}>
            <Heading {...headingStyle}>Paso 3</Heading>
            <img src={Paso3} alt="Paso3" />
            <Text>Nos contactamos para que realices la compra</Text>
          </Col>
        </Row>
      </Container>
    </PasosAreaWrapper>
  )
}

PasosArea.propTypes = {
  headingStyle: PropTypes.object,
}

PasosArea.defaultProps = {
  headingStyle: {
    as: "h4",
  },
}
export default PasosArea
