import React from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { NavItem, NavInner } from "./post-nav.style"

const PostNav = ({ title, path, image, rel }) => {
  const bgImage = convertToBgImage(getImage(image.localFile))
  return (
    <NavItem rel={rel} className="post-nav">
      <Link to={`/${path}`}>
        <NavInner rel={rel} {...bgImage}>
          <h6>{title}</h6>
          <i className="icon fa fa-angle-right"></i>
        </NavInner>
      </Link>
    </NavItem>
  )
}

export default PostNav
