import React, { createContext, useState } from "react"

import { getCart, saveCart } from "../utils/cart"
const CartContext = createContext(null)

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(getCart())
  const updateCart = updatedCart => {
    setCart(updatedCart)
    saveCart(updatedCart)
  }

  const addToCart = (product, qty = 1) => {
    const copy = [...cart]
    //if the product is already there
    const indexOfProduct = copy.findIndex(
      alreadyInCart => alreadyInCart.id === product.id
    )

    if (indexOfProduct !== -1) {
      copy[indexOfProduct].qty += parseInt(qty)

      if (copy[indexOfProduct].qty === 0) {
        //Remove the product from the cart
        copy.splice(indexOfProduct, 1)
      }
    } else {
      //set the qty 1
      product.qty = parseInt(qty)

      //push the product
      copy.push(product)
    }

    updateCart(copy)
  }
  return (
    <CartContext.Provider value={{ cart, addToCart }}>
      {children}
    </CartContext.Provider>
  )
}

export default CartContext

export { CartProvider }
