import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import PageHeader from "../../components/pageheader"
import { Container, Row, Col } from "../../components/ui/wrapper"
import Sidebar from "../../containers/productos/sidebar"
import Pagination from "../../components/producto/pagination"
import ProductoBox from "../../components/producto/layout-one"
import CategoriasArea from "../../containers/productos/categorias-area"
import {
  CategoriaTemplateWrapper,
  ProductoBoxWrapper,
} from "./productos-list.style"

const ProductoList = ({ data, pageContext, location, ...restProps }) => {
  const { productoStyles } = restProps
  const productos = data.allPrismicProducto.edges
  const { currentPage, numberOfPages } = pageContext
  return (
    <Layout location={location}>
      <Seo title={`Producto: Page ${currentPage}`} />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title="Laboratorio"
      />

      <main className="site-wrapper-reveal">
        <CategoriaTemplateWrapper>
          <Container fluid>
            <Row mt="90px">
              <Col>
                <CategoriasArea />
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 2, order: 1 }} xs={{ span: 12, order: 2 }}>
                <Sidebar />
              </Col>
              <Col lg={{ span: 10, order: 2 }} xs={{ span: 12, order: 1 }}>
                <ProductoBoxWrapper>
                  <Row>
                    {productos.map((producto, i) => {
                      return (
                        <Col
                          sm={{ span: 6 }}
                          md={{ span: 3 }}
                          mb="60px"
                          key={`producto-${i}`}
                        >
                          <ProductoBox
                            {...productoStyles}
                            content={producto.node}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </ProductoBoxWrapper>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 10, order: 2 }} xs={{ span: 12, order: 1 }}>
                <Pagination
                  rootPage="/productos"
                  currentPage={currentPage}
                  numberOfPages={numberOfPages}
                />
              </Col>
            </Row>
          </Container>
        </CategoriaTemplateWrapper>
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ProductosListQuery($skip: Int!, $limit: Int!) {
    allPrismicProducto(limit: $limit, skip: $skip) {
      edges {
        node {
          data {
            categoria {
              document {
                ... on PrismicCategoria {
                  uid
                }
              }
            }
            subcategoria {
              document {
                ... on PrismicSubcategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            division {
              document {
                ... on PrismicDivision {
                  uid
                }
              }
            }
            nombre {
              text
            }
            marca {
              document {
                ... on PrismicMarca {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            foto {
              gatsbyImageData
            }
            codigo
          }
          uid
        }
      }
    }
  }
`

ProductoList.propTypes = {
  headingStyle: PropTypes.object,
}

ProductoList.defaultProps = {
  headingStyle: {
    as: "h3",
    mb: "70px",
    textalign: "center",
    child: {
      color: "primary",
    },
    responsive: {
      medium: {
        mb: "50px",
      },
    },
  },
}

export default ProductoList
