import React from "react"
import { Container, Row, Col } from "../../components/ui/wrapper"
import Button from "../../components/ui/button"
import { ErrorWrap } from "./error-area.style"

const ErroArea = () => {
  return (
    <ErrorWrap>
      <Container>
        <Row>
          <Col xl={7} lg={8} ml="auto" mr="auto">
            <h1>404</h1>
            <h2>OPPS! Página no encontrada</h2>
            <p>
              Lo sentimos, pero la página que está buscando no existe, se ha
              eliminado, se ha cambiado de nombre o no está disponible
              temporalmente.
            </p>
            <Button to="/" hover="false">
              Regresar a la página de Inicio
            </Button>
          </Col>
        </Row>
      </Container>
    </ErrorWrap>
  )
}

export default ErroArea
