import React from "react"
import { Container } from "react-bootstrap"
import Bounce from "react-reveal/Bounce"
import { SectionWrap, FunFactGridWrap, FunFactGrid } from "./section.style"
import Counter from "../../../components/counter/layout-one"

const Section = () => {
  return (
    <SectionWrap>
      <Container>
        <FunFactGridWrap>
          <FunFactGrid>
            <Bounce duration={3000} left>
              <Counter countTo={44} text="Años de experiencia" plus />
            </Bounce>
          </FunFactGrid>
          <FunFactGrid>
            <Bounce duration={3000} top>
              <Counter countTo={2000} text="Productos" />
            </Bounce>
          </FunFactGrid>
          <FunFactGrid>
            <Bounce duration={3000} bottom>
              <Counter countTo={3} text="Presencia en países" />
            </Bounce>
          </FunFactGrid>
          <FunFactGrid>
            <Bounce duration={3000} right>
              <Counter countTo={1200} text="Clientes" />
            </Bounce>
          </FunFactGrid>
        </FunFactGridWrap>
      </Container>
    </SectionWrap>
  )
}

export default Section
