import React from "react"
import PropTypes from "prop-types"
import Bounce from "react-reveal/Bounce"
import { SectionTitleWrap } from "./section-title.style"

const SectionTitle = ({ title, subtitle, layout, ...props }) => {
  if (layout === 1) {
    return (
      <SectionTitleWrap {...props} layout={layout}>
        {subtitle && (
          <Bounce duration={3000} left>
            <h6>{subtitle}</h6>
          </Bounce>
        )}
        {title && (
          <Bounce duration={3000} right>
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
          </Bounce>
        )}
      </SectionTitleWrap>
    )
  }
  return (
    <SectionTitleWrap {...props} layout={layout}>
      {title && (
        <Bounce duration={3000} left>
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
        </Bounce>
      )}
      {subtitle && (
        <Bounce duration={3000} right>
          <h6>{subtitle}</h6>
        </Bounce>
      )}
    </SectionTitleWrap>
  )
}

SectionTitle.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  mb: PropTypes.string,
}

SectionTitle.defaultProps = {
  align: "center",
  layout: 1,
}
export default SectionTitle
