import React, { useState, useCallback, useContext } from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import CartContext from "../../../context/CartContext"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Heading from "../../../components/ui/heading"
import { cartTotal } from "../../../utils/cart"
import CheckoutForm from "../../../components/forms/checkout-form"
import Text from "../../../components/ui/text"
import {
  ProductosAreaWrapper,
  ProductosTable,
  ThCentered,
  ThLeft,
  TdCentered,
  TdLeft,
  QuantityAdjusterWrapper,
  AdjusterButton,
} from "./productos-area.style"

const ProductosArea = () => {
  const { cart, addToCart } = useContext(CartContext)

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  return (
    <ProductosAreaWrapper>
      <Container>
        <Row>
          <Col>
            <ProductosTable>
              <thead>
                {cart.length > 0 && (
                  <tr>
                    <ThCentered>FOTO</ThCentered>
                    <ThLeft>NOMBRE DEL PRODUCTO</ThLeft>
                    <ThCentered>CANTIDAD</ThCentered>
                  </tr>
                )}
              </thead>
              <tbody>
                {cart.map((producto, i) => (
                  <tr key={`producto-${i}`}>
                    <TdCentered>
                      <GatsbyImage
                        image={getImage(producto.data.foto)}
                        alt={producto.data.nombre.text}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </TdCentered>
                    <TdLeft>
                      <Heading as="h6">{producto.data.nombre.text}</Heading>
                    </TdLeft>
                    <TdCentered>
                      <QuantityAdjusterWrapper>
                        <AdjusterButton
                          onClick={() => {
                            addToCart(producto, -1)
                            forceUpdate()
                          }}
                        >
                          -
                        </AdjusterButton>
                        {producto.qty}
                        <AdjusterButton
                          onClick={() => {
                            addToCart(producto, 1)
                            forceUpdate()
                          }}
                        >
                          +
                        </AdjusterButton>
                      </QuantityAdjusterWrapper>
                    </TdCentered>
                  </tr>
                ))}
              </tbody>
            </ProductosTable>
            {cart.length > 0 && (
              <Heading as="h4" mt="90px">
                Cantidad Total de Productos: {cartTotal(cart)}
              </Heading>
            )}
          </Col>
          <Col>{cart.length > 0 && <CheckoutForm carrito={cart} />}</Col>
        </Row>
        <Row align="center" mb="90px">
          <Col>
            {cart.length <= 0 && (
              <>
                <Heading as="h4" mt="90px">
                  Carrito Vacío
                </Heading>
                <Text>
                  Agrega los productos que necesitas al carrito de compras
                </Text>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </ProductosAreaWrapper>
  )
}

export default ProductosArea
