import styled from "styled-components"
import { device } from "../../theme"

export const ProductosListWrapper = styled.div``

export const ProductoBox = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`

export const SearchError = styled.div`
  text-align: center;
  h2 {
    font-size: 60px;
    @media ${device.medium} {
      font-size: 50px;
    }
  }
`
