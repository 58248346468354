import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { videoTagString, VideoTag } from "react-video-tag"
import {
  PageHeaderWrap,
  VideoWrapper,
  BreadcrumbWrap,
} from "./page-header.style"

const PageHeader = ({
  esLinea,
  video,
  banner,
  pageContext,
  location,
  title,
}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const disableLinks = []
  const customCrumbLabel = location.pathname.toLowerCase()
  const crumbLabelArr = customCrumbLabel.split("/")
  const label = crumbLabelArr[crumbLabelArr.length - 1]
  const labelArr = label.split("-")
  const bCrumbs = (
    <Breadcrumb
      title={esLinea ? `Línea ${title}` : title}
      crumbs={crumbs}
      crumbLabel={labelArr.join(" ")}
      disableLinks={disableLinks}
    />
  )
  const imageData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "header-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 351, width: 1366, layout: FULL_WIDTH)
        }
      }
    }
  `)
  let bgImage
  if (banner && banner.file) {
    bgImage = convertToBgImage(getImage(banner.file))
  } else {
    bgImage = convertToBgImage(getImage(imageData.file))
  }

  if (video && video.url) {
    videoTagString({
      src: video.url,
      poster: bgImage,
    })

    return (
      <VideoWrapper>
        <BreadcrumbWrap>{bCrumbs}</BreadcrumbWrap>

        <VideoTag
          autoPlay={`${true}`}
          muted={`${true}`}
          playsInline={`${true}`}
          loop={`${true}`}
          src={`${video.url}`}
          poster={`${bgImage}`}
        />
      </VideoWrapper>
    )
  } else {
    return <PageHeaderWrap {...bgImage}>{bCrumbs}</PageHeaderWrap>
  }
}

export default PageHeader
