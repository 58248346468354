import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PoliticaArea from "../containers/politica/politica-area"

const PoliticaPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Política de Privacidad" />
      <Header />
      <main className="site-wrapper-reveal">
        <PoliticaArea />
      </main>
      <Footer />
    </Layout>
  )
}

export default PoliticaPage
