import React from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import { GraciasWrapper } from "./gracias-area.style"

const GraciasArea = () => {
  return (
    <GraciasWrapper>
      <Container>
        <Row>
          <Col>
            <Heading as="h2">¡Gracias!</Heading>
            <Heading as="h3">Hemos recibido tu comunicación</Heading>
          </Col>
        </Row>
      </Container>
    </GraciasWrapper>
  )
}

export default GraciasArea
