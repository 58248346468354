import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import ContactSection from "../containers/contacto/contact-area"
import MapaArea from "../containers/contacto/mapa-area"
import ContactInfoArea from "../containers/contacto/contact-info-area"
import Cta from "../containers/contacto/cta"

const ContactoPage = ({ data, pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Contacto" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Contáctanos"
          banner={data}
        />
        <ContactSection />
        <MapaArea />
        <ContactInfoArea />
        <Cta />
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "contacto-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 351
          quality: 100
          width: 1366
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
        )
      }
    }
  }
`

export default ContactoPage
