import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import Modal from "react-modal"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import HeroArea from "../containers/index/hero"
import Divisiones from "../containers/index/divisiones"
import Productos from "../containers/index/productos-area"
import CertificacionesArea from "../containers/index/certificaciones-area"
import ContadoresArea from "../containers/index/contadores-area"
import AliadosArea from "../containers/index/aliados-area"
import RedesArea from "../containers/index/redes-area"
import Cta from "../containers/contacto/cta"
import AppCta from "../containers/index/app-cta"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const IndexPage = ({ location, data }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
    },
  }

  const Button = styled.button`
    border: 0;
    background-color: #fff;
    color: #4b73b2;
    position: absolute;
    top: 8px;
    right: 4px;
    z-index: 100;
    i {
      font-size: 28px;
    }
  `

  const [modalIsOpen, setIsOpen] = useState(data.prismicModal)

  function closeModal() {
    setIsOpen(false)
  }

  const imagen = data.prismicModal?.data.imagen

  return (
    <Fragment>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Button onClick={closeModal}>
          <i className="fas fa-times-circle" />
        </Button>
        <GatsbyImage image={imagen?.gatsbyImageData} />
      </Modal>
      <Layout location={location}>
        <Seo title="Mercantil S.A." />
        <Header />
        <main className="site-wrapper-reveal">
          <HeroArea />
          <Divisiones />
          <Productos />
          <AliadosArea />
          <CertificacionesArea />
          <ContadoresArea />
          <RedesArea />
          <AppCta />
          <Cta />
        </main>
        <Footer />
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  {
    prismicModal {
      data {
        imagen {
          gatsbyImageData(width: 420)
        }
      }
    }
  }
`

export default IndexPage
