import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import TodosLosProductos from "../containers/productos/todos-los-productos"
import Divisiones from "../containers/productos/divisiones"

const ProductosPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Productos" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Productos"
        />
        <Divisiones />
        <TodosLosProductos />
      </main>
      <Footer />
    </Layout>
  )
}

export default ProductosPage
