import React from "react"
import PropTypes from "prop-types"
import Bounce from "react-reveal/Bounce"
import { HeadingWrapper } from "./heading.style"

const Heading = ({ children, ...props }) => {
  return (
    <HeadingWrapper {...props}>
      <Bounce duration={3000} left>
        {children}
      </Bounce>
    </HeadingWrapper>
  )
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  fontSize: PropTypes.string,
  color: PropTypes.string,
  responsive: PropTypes.object,
}

Heading.defaultProps = {
  as: "h2",
}

export default Heading
