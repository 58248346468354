import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Button from "../../ui/button"
import ProductoFallback from "../../../assets/images/producto-fallback.jpg"
import {
  BoxIconWrap,
  BoxIconInner,
  BoxIconImg,
  BoxIconContent,
  BoxIcontitle,
  BoxIconText,
  BoxIconBtn,
} from "./box-icon.style"

const BoxIcon = ({ content, ...restProps }) => {
  const { buttonStyles, boxStyles, titleStyles, textStyles } = restProps
  const { nombre, foto, categoria, subcategoria, descripcion } = content.data
  const path = content.uid
  let boxImage
  if (foto.gatsbyImageData) {
    boxImage = <GatsbyImage image={getImage(foto)} alt={nombre.text} />
  } else {
    boxImage = <img src={ProductoFallback} alt={nombre.text} />
  }
  return (
    <BoxIconWrap>
      <BoxIconInner {...boxStyles}>
        {foto && <BoxIconImg>{boxImage}</BoxIconImg>}
        <BoxIconContent>
          {nombre && (
            <BoxIcontitle {...titleStyles}>{nombre.text}</BoxIcontitle>
          )}
          {descripcion && (
            <BoxIconText {...textStyles}>
              {descripcion.text.substring(0, 300)}
              {descripcion.text.length > 299 ? " ..." : ""}
            </BoxIconText>
          )}
          <BoxIconBtn>
            <Button
              to={
                subcategoria.document
                  ? `/${categoria.document.data.division.document.uid}/${categoria.document.uid}/${subcategoria.document.uid}/${path}`
                  : `/${categoria.document.data.division.document.uid}/${categoria.document.uid}/${path}`
              }
              as={Link}
              {...buttonStyles}
              icon="far fa-long-arrow-right"
            >
              Ver Más
            </Button>
          </BoxIconBtn>
        </BoxIconContent>
      </BoxIconInner>
    </BoxIconWrap>
  )
}

BoxIcon.propTypes = {
  heading: PropTypes.string,
  descripcion: PropTypes.string,
  path: PropTypes.string,
  boxStyles: PropTypes.object,
  titleStyles: PropTypes.object,
  textStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
}

BoxIcon.defaultProps = {
  buttonStyles: {
    varient: "texted",
    fontWeight: 400,
    hover: "false",
    icondistance: "8px",
  },
  path: "/",
}

export default BoxIcon
