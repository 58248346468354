import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import LibroForm from "../../../components/forms/libro-form"
import { LibroFormWrapper, LeftBox } from "./libro-form-area.style"

const LibroFormArea = ({ headingStyle, textStyle }) => {
  return (
    <LibroFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <LeftBox>
              <Heading {...headingStyle}>
                Haznos saber tu disconformidad o malestar, respecto a los
                productos, servicio o atención.
              </Heading>
              <Text {...textStyle}>
                Conforme a lo establecido en el código de la Protección y
                Defensa del consumidor contamos con un Libro de Reclamaciones a
                tu disposición. Ingresa una queja o reclamo aquí.
              </Text>
            </LeftBox>
          </Col>
          <Col lg={6}>
            <LibroForm />
          </Col>
        </Row>
      </Container>
    </LibroFormWrapper>
  )
}

LibroFormArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
}

LibroFormArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    pl: "34px",
    lineheight: 1.67,
    fontweight: 600,
    child: {
      color: "primary",
    },
    layout: "quote",
  },
  textStyle: {
    mt: "15px",
    fontSize: "18px",
    ml: "34px",
    color: "#696969",
  },
}

export default LibroFormArea
