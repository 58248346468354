import styled from "styled-components"
import { device } from "../../theme"

export const DivisionTemplateWrapper = styled.section`
  padding-top: 89px;
  padding-bottom: 100px;
  @media ${device.medium} {
    padding-top: 72px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 53px;
    padding-bottom: 60px;
  }
`

export const ProductoBoxWrapper = styled.div``

export const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 30px;
`
