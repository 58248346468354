exports.linkResolver = doc => {
  if (doc.type === "division") {
    return `/${doc.uid}`
  }

  // if (doc.type === "categoria") {
  //   return `/${doc.data.division.document.uid}/${doc.uid}`
  // }

  if (doc.type === "servicio") {
    return `/servicios`
  }

  return "/"
}
