import React, { useState } from "react"
import axios from "axios"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { Row, Col } from "../../ui/wrapper"
import Form, { FormGroup, Select, Input, Textarea, Error } from "../../ui/form"
import Button from "../../ui/button"
import Heading from "../../ui/heading"
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"

import "react-datepicker/dist/react-datepicker.css"

const ContactForm = ({ headingStyle }) => {
  registerLocale("es", es)
  const [startDate, setStartDate] = useState(new Date())
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/dc6f6cc3-9aab-46aa-8f73-9cd2f8674afc",
      data: data,
    })
      .then(r => {
        handleServerResponse(true, "Enviado correctamente", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          Fecha de Incidencia
          <FormGroup mb="20px">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              locale="es"
            />
          </FormGroup>
        </Col>
      </Row>
      <Heading {...headingStyle}>
        1. Identificación del consumidor reclamante
      </Heading>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Nombres *"
              ref={register({ required: "Los Nombres son necesarios" })}
            />
            <Error>{errors.name && errors.name.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="apellidos"
              id="apellidos"
              placeholder="Apellidos *"
              ref={register({ required: "Los Apellidos son necesarios" })}
            />
            <Error>{errors.apellidos && errors.apellidos.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="domicilio"
              id="domicilio"
              placeholder="Domicilio *"
              ref={register({ required: "El Domicilio es necesarios" })}
            />
            <Error>{errors.domicilio && errors.domicilio.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Select
              name="tipodocumento"
              id="tipodocumento"
              hover="2"
              ref={register({
                required: "Porfavor selecciona un tipo de documento",
              })}
            >
              <option value="">Tipo de Documento</option>
              <option value="dni">DNI</option>
              <option value="ce">CE</option>
            </Select>
            <Error>
              {errors.tipodocumento && errors.tipodocumento.message}
            </Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="nrodocumento"
              id="nrodocumento"
              placeholder="Número de Documento *"
              ref={register({
                required: "El Número de Documento es necesario",
              })}
            />
            <Error>{errors.nrodocumento && errors.nrodocumento.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email *"
              ref={register({
                required: "Email es necesario",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "email inválido",
                },
              })}
            />
            <Error>{errors.email && errors.email.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Heading {...headingStyle}>
        2. Identidicación del bien contratado (Producto)
      </Heading>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="monto"
              id="monto"
              placeholder="Monto *"
              ref={register({ required: "El Monto es necesario" })}
            />
            <Error>{errors.monto && errors.monto.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="descripcion"
              id="descripcion"
              placeholder="Descripción *"
              ref={register({ required: "La Descripción es necesaria" })}
            />
            <Error>{errors.descripcion && errors.descripcion.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Heading {...headingStyle}>
        3. Detalle de la reclamación y pedido del consumidor (Producto)
      </Heading>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="detalle"
              id="detalle"
              placeholder="Detalle"
              ref={register({
                required: "El Detalle es necesario",
                maxLength: {
                  value: 5000,
                  message: "Máximo de 500 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.detalle && errors.detalle.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="pedido"
              id="pedido"
              placeholder="Pedido"
              ref={register({
                required: "El Pedido es necesario",
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.message && errors.message.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" disabled={serverState.submitting}>
            Enviar
          </Button>
          {serverState.status && (
            <p
              className={`form-output ${
                !serverState.status.ok ? "errorMsg" : "success"
              }`}
            >
              {serverState.status.msg}
            </p>
          )}
        </Col>
      </Row>
    </Form>
  )
}

ContactForm.propTypes = {
  headingStyle: PropTypes.object,
}

ContactForm.defaultProps = {
  headingStyle: {
    as: "h6",
    mb: "30px",
  },
}

export default ContactForm
