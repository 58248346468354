import React, { useState, useContext } from "react"
import CartContext from "../../context/CartContext"
import Anchor from "../../components/ui/anchor"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import parse from "html-react-parser"
import Text from "../../components/ui/text"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import Section, { Box, Row, Col } from "../../components/ui/wrapper"
import { Input } from "../../components/ui/form"
import Seo from "../../components/seo"
import Button from "../../components/ui/button"
import PageHeader from "../../components/pageheader"
import Heading from "../../components/ui/heading"
import ProductoNav from "../../components/productos-nav/layout-two"
import SocialShare from "../../components/producto/social-share"
import {
  SingleProductoWrap,
  ProductoMedia,
  Thumbnail,
  ProductoInfo,
  FooterMetaWrap,
  ProductoNavigation,
  InfoTableList,
  CarritoControls,
  InputWrapper,
  ButtonWrapper,
  RegresarBtn,
  ProductoVideo,
} from "./producto-template.stc"

const ProductoTemplate = ({ data, pageContext, location, ...restProps }) => {
  const document = data.prismicProducto.data
  const [qty, setQty] = useState(1)
  const [aviso, setAviso] = useState("")
  const { addToCart } = useContext(CartContext)
  const {
    codigo,
    descripcion,
    foto,
    marca,
    nombre,
    categoria,
    subcategoria,
    pdf,
    video_link,
  } = document
  const { uid, next, previous } = pageContext
  let video_arr, video_id
  if (video_link.url) {
    video_arr = video_link.url.split("=", -1)
    video_id = video_arr[1]
  }

  const { buttonStyle, sectionStyle, titleStyle } = restProps
  const productFoto = foto.gatsbyImageData ? foto : data.file.childImageSharp
  const prevLink =
    previous &&
    (previous.data.subcategoria.document
      ? `${previous.data.division.document.uid}/${previous.data.categoria.document.uid}/${previous.data.subcategoria.document.uid}/${previous.uid}`
      : `${previous.data.division.document.uid}/${previous.data.categoria.document.uid}/${previous.uid}`)
  const nextLink =
    next &&
    (next.data.subcategoria.document
      ? `${next.data.division.document.uid}/${next.data.categoria.document.uid}/${next.data.subcategoria.document.uid}/${next.uid}`
      : `${next.data.division.document.uid}/${next.data.categoria.document.uid}/${next.uid}`)
  return (
    <Layout location={location}>
      <Header />
      <Seo title={nombre.text} />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={
          subcategoria.document
            ? subcategoria.document.data.nombre.text
            : categoria.document.data.nombre.text
        }
        banner={
          subcategoria.document
            ? subcategoria.document.data.foto
            : categoria.document.data.foto
        }
        esLinea={categoria.document.data.es_linea}
      />
      <main className="site-wrapper-reveal">
        <Section {...sectionStyle}>
          <Row>
            <Col lg={4}>
              <ProductoMedia>
                <Thumbnail>
                  <GatsbyImage
                    image={getImage(productFoto)}
                    alt={nombre.text}
                  />
                </Thumbnail>
              </ProductoMedia>
            </Col>
            <Col lg={8}>
              <SingleProductoWrap>
                <ProductoInfo>
                  <Heading {...titleStyle}>{nombre.text}</Heading>
                  <Text>{parse(descripcion.html)}</Text>
                </ProductoInfo>
                <ProductoVideo>
                  {video_id && (
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${video_id}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </ProductoVideo>
                <InfoTableList>
                  {codigo && (
                    <li>
                      <i className="fa fa-check"></i>Código:{" "}
                      <span>{codigo}</span>
                    </li>
                  )}
                  {marca.document && (
                    <li>
                      <i className="fa fa-check"></i>Marca:{" "}
                      <Anchor path={marca.document.uid}>
                        <span>{marca.document.data.nombre.text}</span>
                      </Anchor>
                    </li>
                  )}
                  {subcategoria.document && (
                    <li>
                      <i className="fa fa-check"></i>
                      <span>{subcategoria.document.data.nombre.text}</span>
                    </li>
                  )}
                </InfoTableList>
                {pdf.url && (
                  <Button
                    to={pdf.url}
                    iconposition="left"
                    varient="outlined"
                    icon="far fa-file-pdf"
                  >
                    Descargar PDF
                  </Button>
                )}

                <Box as="footer">
                  <FooterMetaWrap>
                    <Row>
                      <Col sm={6}>
                        <CarritoControls>
                          <InputWrapper>
                            <Input
                              type="number"
                              value={qty}
                              onChange={event => setQty(event.target.value)}
                            ></Input>
                          </InputWrapper>
                          <ButtonWrapper>
                            <Button
                              icon="fas fa-shopping-cart"
                              onClick={() => {
                                addToCart(data.prismicProducto, qty)
                                setAviso("Producto añadido al carrito")
                              }}
                            >
                              Agregar al carrito
                            </Button>
                          </ButtonWrapper>
                        </CarritoControls>
                        <Heading as="h5">{aviso}</Heading>
                      </Col>
                      <Col sm={6}>
                        <SocialShare title={nombre.text} url={uid} />
                      </Col>
                    </Row>
                  </FooterMetaWrap>

                  <ProductoNavigation>
                    {previous && (
                      <ProductoNav
                        rel="prev"
                        path={prevLink}
                        title={previous.data.nombre.text}
                        image={previous.data.foto}
                      />
                    )}
                    {next && (
                      <ProductoNav
                        rel="next"
                        path={nextLink}
                        title={next.data.nombre.text}
                        image={next.data.foto}
                      />
                    )}
                  </ProductoNavigation>
                </Box>
              </SingleProductoWrap>
              <RegresarBtn>
                <Button
                  icon="far fa-long-arrow-left"
                  onClick={() => window.history.back()}
                  {...buttonStyle}
                >
                  Regresar
                </Button>
              </RegresarBtn>
            </Col>
          </Row>
        </Section>
      </main>
      <Footer />
    </Layout>
  )
}

ProductoTemplate.propTypes = {
  sectionStyle: PropTypes.object,
}

ProductoTemplate.defaultProps = {
  sectionStyle: {
    pt: "120px",
    pb: "120px",
    responsive: {
      large: {
        pt: "100px",
        pb: "100px",
      },
      medium: {
        pt: "80px",
        pb: "80px",
      },
      small: {
        pt: "60px",
        pb: "60px",
        fullwidth: true,
      },
    },
  },
  titleStyle: {
    as: "h1",
    fontSize: "40px",
    mb: "15px",
    wordwrap: "break-word",
    responsive: {
      medium: {
        fontSize: "35px",
      },
      small: {
        fontSize: "25px",
      },
    },
  },
  buttonStyle: {
    varient: "texted",
    fontWeight: 500,
    fontSize: "14px",
    pb: "4px",
    hover: "false",
    icondistance: "4px",
    iconsize: "16px",
    iconposition: "left",
    border: {
      bottom: {
        width: "1px",
      },
    },
  },
}

export const query = graphql`
  query ($uid: String!) {
    file(relativePath: { eq: "producto-fallback360.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 360, height: 360)
      }
    }
    prismicProducto(uid: { eq: $uid }) {
      _previewable
      id
      data {
        codigo
        descripcion {
          html
        }
        foto {
          gatsbyImageData(
            aspectRatio: 1
            width: 360
            height: 360
            placeholder: BLURRED
          )
        }
        marca {
          document {
            ... on PrismicMarca {
              uid
              data {
                nombre {
                  text
                }
              }
            }
          }
        }
        nombre {
          text
        }
        pdf {
          url
        }
        video_link {
          url
        }
        categoria {
          document {
            ... on PrismicCategoria {
              uid
              data {
                nombre {
                  text
                }
                es_linea
                foto {
                  gatsbyImageData(
                    width: 1366
                    height: 351
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        subcategoria {
          document {
            ... on PrismicSubcategoria {
              uid
              data {
                nombre {
                  text
                }
                foto {
                  gatsbyImageData(
                    width: 1366
                    height: 351
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ProductoTemplate)
