import * as React from "react"
import PropTypes from "prop-types"
import Layout from "../containers/layout/layout"
import Seo from "../components/seo"
import ErrorArea from "../containers/error-area"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="404: No se encontró" />
    <ErrorArea />
  </Layout>
)

NotFoundPage.propTypes = {
  location: PropTypes.string,
}

export default NotFoundPage
