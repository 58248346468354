import React from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import BoxIcon from "../../../components/box-icon/layout-three"
import SectionTitle from "../../../components/ui/section-title"
import { data } from "./data"
import { SectionWrap } from "./style"

const BoxIconSection = () => {
  const datalarga = data.pop()
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Políticas de la empresa para
entregarte soluciones óptimas"
              mb="60px"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {data.map((item, i) => (
              <BoxIcon
                key={i}
                id={item.id}
                title={item.title}
                desc={item.desc}
                icon={item.icon}
              />
            ))}
          </Col>
          <Col md={6}>
            <BoxIcon
              id={datalarga.id}
              title={datalarga.title}
              desc={datalarga.desc}
              icon={datalarga.icon}
            />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default BoxIconSection
