import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import BoxIcon from "../../../components/box-icon/layout-four"
import { SectionWrap } from "./style"
import { GatsbyImage } from "gatsby-plugin-image"

const IntroArea = ({ headingStyle, descStyle }) => {
  const introData = useStaticQuery(graphql`
    {
      prismicNosotros {
        data {
          intro_desc {
            text
          }
          intro_foto {
            gatsbyImageData
          }
          intro_titulo {
            text
          }
          propositos {
            desc {
              text
            }
            icono {
              gatsbyImageData
            }
            titulo {
              text
            }
          }
        }
      }
    }
  `)

  const { intro_desc, intro_foto, intro_titulo, propositos } =
    introData.prismicNosotros.data

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={6}>
            <Heading {...headingStyle}>
              {intro_titulo && intro_titulo.text}
            </Heading>
            <Text {...descStyle}>{intro_desc && intro_desc.text}</Text>
            {propositos &&
              propositos.map((proposito, i) => (
                <BoxIcon
                  icon={proposito.icono.gatsbyImageData}
                  title={proposito.titulo.text}
                  desc={proposito.desc.text}
                />
              ))}
          </Col>
          <Col lg={6} mt="auto" mb="auto">
            <GatsbyImage image={intro_foto.gatsbyImageData} />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

IntroArea.defaultProps = {
  headingStyle: {
    as: "h4",
    mb: "20px",
    child: {
      color: "primary",
    },
  },
  descStyle: {
    maxwidth: "760px",
    m: "auto",
    mb: "70px",
  },
}

export default IntroArea
