import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Anchor from "../../../components/ui/anchor"
import { StyledSection, StyledHeading, StyledBG } from "./style"

const AppCta = () => {
  return (
    <StyledSection>
      <StyledBG>
        <StaticImage src="../../../assets/images/cta-bg.png" alt="CTA BG" />
      </StyledBG>
      <Container>
        <Row alignItems="center" textAlign={["center", null, null, "left"]}>
          <Col xl={8} lg={7}>
            <StyledHeading>Descarga nuestra APP</StyledHeading>
            <span>
              Donde podrás realizar cálculos metalúrgicos de molienda y
              dosificación de reactivos de forma rápida y sencilla.
            </span>
          </Col>
          <Col xl={4} lg={5} className="text-center">
            <Anchor href="https://play.google.com/store/apps/details?id=com.mercantilsa.mercantil&fbclid=IwAR3JgCG8_NIXn9Zj13gQgR27E2BbXshWybnNJlcenMPtkaytoelxUjErQAM">
              <StaticImage
                src="../../../assets/images/google-play-badge.png"
                alt="Descargar em Google Play"
              />
            </Anchor>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}

export default AppCta
