import React, { useState } from "react"
import axios from "axios"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import { Row, Col } from "../../ui/wrapper"
import Form, { FormGroup, Input, Textarea, Error } from "../../ui/form"
import Button from "../../ui/button"

const ConsultForm = ({ btnStyle }) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  })
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/81e7f47b-4961-4c01-b288-df92b9befdc3",
      data: data,
    })
      .then(r => {
        handleServerResponse(true, "Gracias por contactarnos", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Nombre *"
              ref={register({ required: "Nombre es necesario" })}
            />
            <Error>{errors.name && errors.name.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email *"
              ref={register({
                required: "Email es necesario",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "el email es inválido",
                },
              })}
            />
            <Error>{errors.email && errors.email.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="empresa"
              id="empresa"
              placeholder="Empresa *"
              ref={register({ required: "Empresa es necesario" })}
            />
            <Error>{errors.empresa && errors.empresa.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="telefono"
              id="telefono"
              placeholder="Teléfono *"
              ref={register({ required: "Teléfono es necesario" })}
            />
            <Error>{errors.telefono && errors.telefono.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="mensaje"
              id="mensaje"
              placeholder="Mensaje"
              ref={register({
                required: "Mensaje es necesario",
                maxLength: {
                  value: 300,
                  message: "Máximo de 300 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.mensaje && errors.mensaje.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" disabled={serverState.submitting} {...btnStyle}>
            Enviar Mensaje
          </Button>
          {serverState.status && (
            <p
              className={`form-output ${
                !serverState.status.ok ? "errorMsg" : "success"
              }`}
            >
              {serverState.status.msg}
            </p>
          )}
        </Col>
      </Row>
    </Form>
  )
}

ConsultForm.propTypes = {
  btnStyle: PropTypes.object,
}

ConsultForm.defaultProps = {
  btnStyle: {
    hover: 2,
    pl: "54px",
    pr: "54px",
  },
}

export default ConsultForm
