import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import PageHeader from "../../components/pageheader"
import { Container, Row, Col } from "../../components/ui/wrapper"
import Sidebar from "../../containers/productos/sidebar"
import CategoriasArea from "../../containers/productos/categorias-area"
import ProductoBox from "../../components/producto/layout-one"
import Text from "../../components/ui/text"
import {
  SubCategoriaTemplateWrapper,
  ProductoBoxWrapper,
  SectionTitle,
} from "./subcategoria-template.stc"

const SubCategoriaTemplate = ({
  data,
  pageContext,
  location,
  ...restProps
}) => {
  const document = data.lasubcategoria.data
  const { productoStyles, descStyle } = restProps
  const { uid } = pageContext
  const productos = data.losproductos.edges
  const { nombre, categoria, es_linea, video_de_fondo, foto, descripcion } =
    document
  return (
    <Layout location={location}>
      <Seo
        title={`${categoria.document.data.division.document.data.nombre.text} | ${categoria.document.data.nombre.text} | ${nombre.text}`}
      />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={nombre.text}
        banner={foto}
        video={video_de_fondo}
        esLinea={es_linea}
      />
      <main className="site-wrapper-reveal">
        <SubCategoriaTemplateWrapper>
          <Container fluid>
            {descripcion && (
              <Row>
                <Col lg={12}>
                  <SectionTitle>
                    <Text {...descStyle}>{descripcion.text}</Text>
                  </SectionTitle>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <CategoriasArea
                  divisionActual={categoria.document.data.division.document.uid}
                  categoriaActiva={categoria.document.uid}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 2, order: 1 }} xs={{ span: 12, order: 2 }}>
                <Sidebar
                  divisionActual={categoria.document.data.division.document.uid}
                  categoriaActual={categoria.document.uid}
                  subcategoriaActiva={uid}
                />
              </Col>
              <Col lg={{ span: 10, order: 2 }} xs={{ span: 12, order: 1 }}>
                <ProductoBoxWrapper>
                  <Row>
                    {productos.map((producto, i) => {
                      return (
                        <Col
                          sm={{ span: 6 }}
                          md={{ span: 3 }}
                          mb="60px"
                          key={`producto-${i}`}
                        >
                          <ProductoBox
                            {...productoStyles}
                            content={producto.node}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </ProductoBoxWrapper>
              </Col>
            </Row>
          </Container>
        </SubCategoriaTemplateWrapper>
      </main>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query SubCategoriaPageQuery($uid: String!) {
    lasubcategoria: prismicSubcategoria(uid: { eq: $uid }) {
      _previewable
      data {
        nombre {
          text
        }
        descripcion {
          text
        }
        video_de_fondo {
          url
        }
        categoria {
          document {
            ... on PrismicCategoria {
              uid
              data {
                nombre {
                  text
                }
                division {
                  document {
                    ... on PrismicDivision {
                      uid
                      data {
                        nombre {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        es_linea
        foto {
          gatsbyImageData(height: 351, width: 1366, layout: FULL_WIDTH)
        }
      }
    }
    losproductos: allPrismicProducto(
      filter: { data: { subcategoria: { uid: { eq: $uid } } } }
    ) {
      edges {
        node {
          data {
            categoria {
              document {
                ... on PrismicCategoria {
                  uid
                }
              }
            }
            subcategoria {
              document {
                ... on PrismicSubcategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            division {
              document {
                ... on PrismicDivision {
                  uid
                }
              }
            }
            nombre {
              text
            }
            marca {
              document {
                ... on PrismicMarca {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            foto {
              gatsbyImageData(width: 232, height: 232)
            }
            codigo
          }
          uid
        }
      }
    }
  }
`

SubCategoriaTemplate.propTypes = {
  headingStyle: PropTypes.object,
}

SubCategoriaTemplate.defaultProps = {
  headingStyle: {
    as: "h3",
    mb: "70px",
    textalign: "center",
    child: {
      color: "primary",
    },
    responsive: {
      medium: {
        mb: "50px",
      },
    },
  },
}

export default withPrismicPreview(SubCategoriaTemplate)
