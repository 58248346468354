import React from "react"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./src/linkResolver"
import PageTemplate from "./src/templates/division-template/division-template"
import { CartProvider } from "./src/context/CartContext"

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: `mercantil`,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
        }),
      },
    ]}
  >
    <CartProvider>{element}</CartProvider>
  </PrismicPreviewProvider>
)
