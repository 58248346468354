import styled from "styled-components"

export const ProductosAreaWrapper = styled.div``

export const ProductosTable = styled.table`
  width: 100%;
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
`

export const ThCentered = styled.th`
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  text-align: center;
  color: #252525;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
`
export const ThLeft = styled.th`
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  color: #252525;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
`

export const TdCentered = styled.td`
  padding: 10px;
  text-align: center;
`

export const TdLeft = styled.td`
  padding: 10px;
`

export const QuantityAdjusterWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeight.heading};
  font-family: ${props => props.theme.fontFamily.heading};
  background-color: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  color: #333333;
  > div {
    margin: auto 0;
    padding: 1px 10px;
  }
`

export const AdjusterButton = styled.div`
  cursor: pointer;
  font-weight: bold;
`
