import styled from "styled-components"
import { device } from "../../../theme"

export const BoxIconInner = styled.div``

export const BoxIconTop = styled.div`
  margin-bottom: 10px;
`

export const IconWrap = styled.div`
  min-width: 60px;
  margin-right: 10px;
`

export const Heading = styled.h5`
  color: ${props => props.theme.colors.themeColor};
`

export const BoxIconBottom = styled.div``

export const Text = styled.p``

export const BoxIconWrap = styled.div`
  display: flex;
  margin: 20px 0;
`
