import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import Anchor from "../../../components/ui/anchor"
import {
  ContactInfoWrapper,
  InfoBoxWrap,
  InfoBox,
} from "./contact-info-area.style"

const ContactInfoArea = ({ headingStyle, textStyle, phoneStyle }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            address
            email
            fijo
            celular
          }
        }
      }
    }
  `)
  const { address, email, fijo, celular } = data.site.siteMetadata.contact
  return (
    <ContactInfoWrapper>
      <Container>
        <Row>
          <Col lg={4}>
            <InfoBoxWrap>
              <Heading {...headingStyle}>Lima</Heading>
              <InfoBox>
                {address && <Text {...textStyle}>{address}</Text>}
                {email && (
                  <Anchor {...textStyle} path={`mailto:${email}`}>
                    {email}
                  </Anchor>
                )}
                {celular && (
                  <Text {...textStyle} {...phoneStyle}>
                    {celular}
                  </Text>
                )}
                {fijo && (
                  <Text {...textStyle} {...phoneStyle}>
                    {fijo}
                  </Text>
                )}
              </InfoBox>
            </InfoBoxWrap>
          </Col>
        </Row>
      </Container>
    </ContactInfoWrapper>
  )
}

ContactInfoArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
  phoneStyle: PropTypes.object,
}

ContactInfoArea.defaultProps = {
  headingStyle: {
    as: "h5",
    mb: "20px",
  },
  textStyle: {
    mb: "7px",
  },
  phoneStyle: {
    fontweight: 800,
    color: "headingColor",
  },
}

export default ContactInfoArea
