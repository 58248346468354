import React, { useState } from "react"
import axios from "axios"
import { subYears } from "date-fns"
import { Row, Col } from "../../ui/wrapper"
import { FormGroup, Select, Input } from "../../ui/form"
import Button from "../../ui/button"
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Heading from "../../ui/heading"
import { SuccessWrap } from "./postulante-form.style"

import "react-datepicker/dist/react-datepicker.css"

const PostulanteForm = () => {
  registerLocale("es", es)

  const date = new Date()
  const minDate = subYears(date, 65)
  const maxDate = subYears(date, 18)
  const [startDate, setStartDate] = useState("")

  const [formStatus, setFormStatus] = useState(false)
  const [query, setQuery] = useState({
    dni: "",
    nacimiento: "",
    pais: "",
    nombres: "",
    email: "",
    departamento: "",
    apellidos: "",
    movil: "",
    distrito: "",
    gradodeinstruccion: "",
    centrodeestudios: "",
    situacion: "",
    niveldeexcel: "",
    niveldeingles: "",
    laborando: "",
    ultimopuesto: "",
    empresa: "",
    expectativas: "",
  })

  const handleFileChange = () => e => {
    setQuery(prevState => ({
      ...prevState,
      files: e.target.files[0],
    }))
  }

  const handleChange = () => e => {
    const name = e.target.name
    const value = e.target.value
    setQuery(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData()
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value)
    })

    axios
      .post(
        "https://getform.io/f/1ec29acc-760a-4fea-875d-774b50a55692",
        formData,
        {
          headers: { Accept: "application/json" },
        }
      )
      .then(function (response) {
        setFormStatus(true)
        setQuery({
          dni: "",
          nacimiento: "",
          pais: "",
          nombres: "",
          email: "",
          departamento: "",
          apellidos: "",
          movil: "",
          distrito: "",
          gradodeinstruccion: "",
          centrodeestudios: "",
          situacion: "",
          niveldeexcel: "",
          niveldeingles: "",
          laborando: "",
          ultimopuesto: "",
          empresa: "",
          expectativas: "",
        })
        console.log(response)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <form
      acceptCharset="UTF-8"
      method="POST"
      enctype="multipart/form-data"
      id="gatsbyForm"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col>
          <Heading as="h5">Información Personal</Heading>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="dni"
              id="dni"
              placeholder="DNI /CE"
              required
              value={query.dni}
              onChange={handleChange()}
            />
            <Input
              name="nacimiento"
              id="nacimiento"
              // selected={startDate}
              onChange={handleChange()}
              placeholder="Fecha de nacimiento"
              // locale="es"
              // showYearDropdown
              // dateFormatCalendar="MMMM"
              // yearDropdownItemNumber={150}
              // minDate={minDate}
              // maxDate={maxDate}
              // scrollableYearDropdown
              value={query.nacimiento}
            />
            <Input
              type="text"
              name="pais"
              id="pais"
              placeholder="País"
              required
              value={query.pais}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="nombres"
              id="nombres"
              placeholder="Nombres"
              required
              value={query.nombres}
              onChange={handleChange()}
            />
            <Input
              type="text"
              name="email"
              id="email"
              placeholder="E-mail"
              required
              value={query.email}
              onChange={handleChange()}
            />
            <Input
              type="text"
              name="departamento"
              id="departamento"
              placeholder="Departamento"
              required
              value={query.departamento}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="apellidos"
              id="apellidos"
              placeholder="Apellidos"
              required
              value={query.apellidos}
              onChange={handleChange()}
            />
            <Input
              type="text"
              name="movil"
              id="movil"
              placeholder="Móvil"
              required
              value={query.movil}
              onChange={handleChange()}
            />
            <Input
              type="text"
              name="distrito"
              id="distrito"
              placeholder="Distrito"
              required
              value={query.distrito}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <Heading as="h5">Información Profesional</Heading>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Select
              name="gradodeinstruccion"
              id="gradodeinstruccion"
              hover="2"
              required
              value={query.gradodeinstruccion}
              onChange={handleChange()}
            >
              <option value="">Grado de instrucción</option>
              <option value="Estudiante Técnico">Estudiante Técnico</option>
              <option value="Estudiante universitario">
                Estudiante universitario
              </option>
              <option value="Técnico">Técnico</option>
              <option value="Egresado o Bachille">Egresado o Bachiller</option>
              <option value="Titulado">Titulado</option>
              <option value="Maestría">Maestría</option>
            </Select>
          </FormGroup>
        </Col>

        <Col lg={4}>
          <FormGroup mb="20px">
            <Select
              name="centrodeestudios"
              id="centrodeestudios"
              hover="2"
              required
              value={query.centrodeestudios}
              onChange={handleChange()}
            >
              <option value="">Centro de estudios</option>
              <option value="Universidad">Universidad</option>
              <option value="Instituto">Instituto</option>
            </Select>
          </FormGroup>
        </Col>

        <Col lg={4}>
          <FormGroup mb="20px">
            <Select
              name="situacion"
              id="situacion"
              hover="2"
              value={query.situacion}
              onChange={handleChange()}
            >
              <option value="">Situación</option>
              <option value="En Curso">En Curso</option>
              <option value="Truncos">Truncos</option>
              <option value="Culminados">Culminados</option>
            </Select>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Select
              name="niveldeexcel"
              id="niveldeexcel"
              hover="2"
              required
              value={query.niveldeexcel}
              onChange={handleChange()}
            >
              <option value="">Nivel de Excel</option>
              <option value="Básico">Básico</option>
              <option value="Intermedio">Intermedio</option>
              <option value="Avanzado">Avanzado</option>
            </Select>
          </FormGroup>
        </Col>
        <Col lg={4}>
          <Select
            name="niveldeingles"
            id="niveldeingles"
            hover="2"
            required
            value={query.niveldeingles}
            onChange={handleChange()}
          >
            <option value="">Nivel de Inglés</option>
            <option value="Básico">Básico</option>
            <option value="Intermedio">Intermedio</option>
            <option value="Avanzado">Avanzado</option>
          </Select>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Select
              name="laborando"
              id="laborando"
              hover="2"
              required
              value={query.laborando}
              onChange={handleChange()}
            >
              <option value="">¿Laborando?</option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Select>
          </FormGroup>
        </Col>

        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="ultimopuesto"
              id="ultimopuesto"
              placeholder="Último Puesto"
              value={query.ultimopuesto}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="rubro"
              id="rubro"
              placeholder="Rubro"
              value={query.rubro}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="empresa"
              id="empresa"
              placeholder="Empresa de procedencia"
              value={query.empresa}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="expectativas"
              id="expectativas"
              placeholder="Expectativas salariales"
              value={query.expectativas}
              onChange={handleChange()}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <input name="file" type="file" onChange={handleFileChange()} />
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            <Button type="submit">Enviar</Button>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={4}>
          <FormGroup mb="20px">
            {formStatus ? (
              <SuccessWrap>Tu mensaje ha sido enviado.</SuccessWrap>
            ) : (
              ""
            )}
          </FormGroup>
        </Col>
      </Row>
    </form>
  )
}

export default PostulanteForm
