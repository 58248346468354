import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Zoom from "react-reveal/Zoom"
import {
  BoxLargeImgWrap,
  BoxLargeImgInner,
  BoxLargeImgMedia,
  BoxLargeImgHeading,
  HeadingWrap,
} from "./box-large-image.style"

const BoxLargeImage = ({ imageSrc, title, desc, path, ...boxStyles }) => {
  const { headingStyle, descStyle, buttonStyle, ...restStyles } = boxStyles

  return (
    <BoxLargeImgWrap {...restStyles}>
      <BoxLargeImgInner>
        {imageSrc && (
          <BoxLargeImgMedia>
            <Zoom>
              <GatsbyImage image={getImage(imageSrc)} alt={title} />
            </Zoom>
          </BoxLargeImgMedia>
        )}
        <BoxLargeImgHeading>
          {title && <HeadingWrap as="h5">{title}</HeadingWrap>}
        </BoxLargeImgHeading>
      </BoxLargeImgInner>
    </BoxLargeImgWrap>
  )
}

export default BoxLargeImage
