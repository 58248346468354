export const saveCart = cart => {
  localStorage.setItem("cart", JSON.stringify(cart))
}

export const getCart = () => {
  try {
    const cart = JSON.parse(localStorage.getItem("cart"))
    if (cart) {
      return cart
    }
  } catch (err) {}
  return []
}

export const cartTotal = cart => {
  const total = cart.reduce((counter, product) => {
    return counter + product.qty
  }, 0)
  return total
}
