import React from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import PageHeader from "../../components/pageheader"
import { Container, Row, Col } from "../../components/ui/wrapper"
import Producto from "../../components/producto/layout-two"
import Heading from "../../components/ui/heading"
import {
  ProductosListWrapper,
  SearchError,
  ProductoBox,
} from "./buscar-template.stc"

const BuscarTemplate = ({ data, pageContext, location, ...restProps }) => {
  const productos = data.allPrismicProducto.edges || []
  const { state } = location
  const query = state && state.query
  let filteredProductos = []
  if (state) {
    filteredProductos = productos.filter(post => {
      const { nombre, marca, descripcion, categoria, subcategoria } =
        post.node.data

      return (
        (nombre && nombre.text.toLowerCase().includes(query)) ||
        (descripcion && descripcion.text.toLowerCase().includes(query)) ||
        (categoria.document &&
          categoria.document.data.nombre.text.toLowerCase().includes(query)) ||
        (subcategoria.document &&
          subcategoria.document.data.nombre.text
            .toLowerCase()
            .includes(query)) ||
        (marca.document &&
          marca.document.data.nombre.text.toLowerCase().includes(query))
      )
    })
  }

  return (
    <Layout location={location}>
      <Seo title={`Buscar`} />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={query ? `Buscar: ${query}` : "Sin resultados"}
      />
      <main className="site-wrapper-reveal">
        <ProductosListWrapper>
          <Container>
            <Row mt="90px" mb="90px">
              <Col>
                <Heading as="h5">
                  Productos encontrados: {filteredProductos.length}
                </Heading>
              </Col>
            </Row>
            <Row>
              <Col>
                {filteredProductos.map(producto => (
                  <ProductoBox key={producto.node.uid}>
                    <Producto content={producto.node} />
                  </ProductoBox>
                ))}
                {filteredProductos.length === 0 && (
                  <SearchError>
                    <h2>No se encontraron productos.</h2>
                  </SearchError>
                )}
              </Col>
            </Row>
          </Container>
        </ProductosListWrapper>
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ProductosPorBusquedaQuery {
    allPrismicProducto(sort: { fields: data___nombre___text, order: ASC }) {
      edges {
        node {
          data {
            nombre {
              text
            }
            foto {
              gatsbyImageData
            }
            marca {
              document {
                ... on PrismicMarca {
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            categoria {
              document {
                ... on PrismicCategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                    division {
                      document {
                        ... on PrismicDivision {
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
            subcategoria {
              document {
                ... on PrismicSubcategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            descripcion {
              text
            }
          }
          uid
        }
      }
    }
  }
`

export default BuscarTemplate
