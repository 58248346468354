import styled from "styled-components"

export const LocationBtnWrap = styled.div`
  margin-top: ${props => props.mt};
`

export const LocationBtnText = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  .button-icon {
    vertical-align: middle;
    height: 56px;
    width: 56px;
    line-height: 56px;
    border-radius: 50%;
    text-align: center;
    background: #f6f2ed;
    margin-right: 14px;
    display: inline-block;
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #4267b2;
      font-size: 32px;
    }
  }
  .button-text {
    font-weight: 500;
    color: #4b73b2;
    position: relative;
    &:after {
      content: "";
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor;
    }
  }
  &:hover {
    .button-text {
      &:after {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }
`
