import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import ServiciosArea from "../containers/servicios/servicios-area"

const ServiciosPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Servicios" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Servicios"
        />
        <ServiciosArea />
      </main>
      <Footer />
    </Layout>
  )
}

export default ServiciosPage
