import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Row, Col } from "../../ui/wrapper"
import Form, { FormGroup, Select, Input, Textarea, Error } from "../../ui/form"
import Button from "../../ui/button"
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"

import "react-datepicker/dist/react-datepicker.css"

const ContactForm = () => {
  registerLocale("es", es)
  const [startDate, setStartDate] = useState(new Date())
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://usebasin.com/f/5453e8f8107f",
      data: data,
    })
      .then(r => {
        handleServerResponse(true, "Enviado correctamente", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Select
              name="Tipo de denuncia"
              id="tipo_de_denuncia"
              hover="2"
              ref={register({
                required: "Porfavor selecciona un tipo de denuncia",
              })}
            >
              <option value="">Tipo de Denuncia</option>
              <option value="administracion-desleal">
                Administración desleal de ejecutivos
              </option>
              <option value="apropiacion-ilicita">Apropiación ilícita</option>
              <option value="soborno-corrupcion">Soborno/Corrupción</option>
              <option value="evasion-fiscal">Evasión fiscal</option>
              <option value="concertacion">Concertación</option>
              <option value="conflicto-de-interes">
                Conflicto de intereses
              </option>
              <option value="contratacion-irregular">
                Contratación pública irregular
              </option>
            </Select>
            <Error>
              {errors.tipo_de_denunciadenuncia &&
                errors.tipo_de_denuncia.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          Fecha de los hechos
          <FormGroup mb="20px">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              locale="es"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="Detalle de los hechos"
              id="detalle_de_los_hechos"
              placeholder="Detalle de los hechos"
              ref={register({
                required: "El Detalle es necesario",
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.detalle_de_los_hechos &&
                errors.detalle_de_los_hechos.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="Entidades Comprometidas"
              id="entidades_comprometidas"
              placeholder="Entidad(es) comprendida(s)"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.entidades_comprometidas &&
                errors.entidades_comprometidas.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Select
              name="Localidad de los hechos"
              id="localidad_de_los_hechos"
              hover="2"
              ref={register({
                required: "Porfavor selecciona una localidad",
              })}
            >
              <option value="">Localidad de los Hechos</option>
              <option value="Amazonas">Amazonas</option>
              <option value="Áncash">Áncash</option>
              <option value="Apurímac">Apurímac</option>
              <option value="Arequipa">Arequipa</option>
              <option value="Ayacucho">Ayacucho</option>
              <option value="Cajamarca">Cajamarca</option>
              <option value="Callao (Provincia)">Callao (Provincia)</option>
              <option value="Cuzco">Cuzco</option>
              <option value="Huancavelica">Huancavelica</option>
              <option value="Huánuco">Huánuco</option>
              <option value="Ica">Ica</option>
              <option value="Junín">Junín</option>
              <option value="La Libertad">La Libertad</option>
              <option value="Lambayeque">Lambayeque</option>
              <option value="Lima">Lima</option>
              <option value="Loreto">Loreto</option>
              <option value="Madre de Dios">Madre de Dios</option>
              <option value="Moquegua">Moquegua</option>
              <option value="Pasco">Pasco</option>
              <option value="Piura">Piura</option>
              <option value="Puno">Puno</option>
              <option value="San Martín">San Martín</option>
              <option value="Tacna">Tacna</option>
              <option value="Tumbes">Tumbes</option>
              <option value="Ucayali">Ucayali</option>
            </Select>
            <Error>
              {errors.localidad_de_los_hechos &&
                errors.localidad_de_los_hechos.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="Trabajadores comprendidos"
              id="trabajadores_comprendidos"
              placeholder="Trabajadores comprendidos"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.trabajadores_comprendidos &&
                errors.trabajadores_comprendidos.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="Relación laboral con el denunciado"
              id="ralacion_laboral_con_el_denunciado"
              placeholder="Relación Laboral con el denunciado"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.relacion_laboral_con_el_denunciado &&
                errors.relacion_laboral_con_el_denunciado.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="Fuente de informacion"
              id="fuente_de_informacion"
              placeholder="Fuente de información o hechos relevantes para investigar"
              ref={register({
                required: "La fuente es necesaria",
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.fuente_de_informacion &&
                errors.fuente_de_informacion.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="Pruebas sustentatorias"
              id="pruebas_sustentatorias"
              placeholder="Pruebas Sustentatorias"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.pruebas_sustentatorias &&
                errors.pruebas_sustentatorias.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="file"
              name="Pruebas"
              id="pruebas"
              placeholder="Pruebas *"
            />
            <Error>{errors.pruebas && errors.pruebas.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12} mb="12px">
          En caso desee recibir información sobre el estado de su denuncia por
          favor ingrese un correo electrónico al que podamos enviarle la
          información. Si desea mantener en reserva su identidad puede colocar
          un correo que no sea personal.
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email *"
              ref={register({})}
            />
            <Error>{errors.email && errors.email.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" disabled={serverState.submitting}>
            Enviar
          </Button>
          {serverState.status && (
            <p
              className={`form-output ${
                !serverState.status.ok ? "errorMsg" : "success"
              }`}
            >
              {serverState.status.msg}
            </p>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
