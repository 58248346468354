import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {
  useMergePrismicPreviewData,
  withPrismicPreview,
} from "gatsby-plugin-prismic-previews"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Swiper from "../../../components/ui/swiper"
import {
  HeroWrapper,
  HeroTextBox,
  TitleHeading,
  SubTitleHeading,
} from "./hero-area.style"

const HeroArea = props => {
  const heroDataQuery = useStaticQuery(graphql`
    {
      allPrismicBanner {
        edges {
          node {
            _previewable
            data {
              titulo {
                text
              }
              subtitulo {
                text
              }
              imagen {
                url
              }
            }
          }
        }
      }
    }
  `)
  const { data, isPreview } = useMergePrismicPreviewData(heroDataQuery)
  const banners = data.allPrismicBanner.edges
  const { sliderSettings } = props

  return (
    <HeroWrapper>
      <Row noGutter={true}>
        <Col lg={12}>
          <Swiper settings={sliderSettings}>
            {banners.map(({ node }, index) => (
              <div key={index} className="item">
                <img src={node.data.imagen.url} alt={node.data.titulo.text} />
                <Container>
                  <HeroTextBox>
                    {node.data.titulo && (
                      <TitleHeading>{node.data.titulo.text}</TitleHeading>
                    )}
                    {node.data.subtitulo && (
                      <SubTitleHeading>
                        {node.data.subtitulo.text}
                      </SubTitleHeading>
                    )}
                  </HeroTextBox>
                </Container>
              </div>
            ))}
          </Swiper>
        </Col>
      </Row>
    </HeroWrapper>
  )
}

HeroArea.propTypes = {
  sliderSettings: PropTypes.object,
}

HeroArea.defaultProps = {
  sliderSettings: {
    arrows: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: true,
    pagination: false,
    speed: 500,
    delay: 5000,
    effect: "fade",
    paginationStyle: {
      mt: "30px",
    },
  },
}

export default withPrismicPreview(HeroArea)
