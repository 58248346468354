import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Row, Col } from "../../ui/wrapper"
import Form, { FormGroup, Select, Input, Textarea, Error } from "../../ui/form"
import Button from "../../ui/button"
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"

import "react-datepicker/dist/react-datepicker.css"

const ContactForm = () => {
  registerLocale("es", es)
  const [startDate, setStartDate] = useState(new Date())
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/4a6bd4ec-8a95-459d-b84c-9dc78f1df884",
      data: data,
    })
      .then(r => {
        handleServerResponse(true, "Thanks! for contact with us", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Select
              name="tipodenuncia"
              id="tipodenuncia"
              hover="2"
              ref={register({
                required: "Porfavor selecciona un tipo de denuncia",
              })}
            >
              <option value="">Tipo de Denuncia</option>
              <option value="Violencia de Género">Violencia de Género</option>
              <option value="Hostigamiento Sexual">Hostigamiento Sexual</option>
              <option value="Discriminación">Discriminación</option>
            </Select>
            <Error>{errors.tipodenuncia && errors.tipodenuncia.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Nombres *"
              ref={register({ required: "Los Nombres son necesarios" })}
            />
            <Error>{errors.name && errors.name.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="apellidos"
              id="apellidos"
              placeholder="Apellidos *"
              ref={register({ required: "Los Apellidos son necesarios" })}
            />
            <Error>{errors.apellidos && errors.apellidos.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          Fecha de los hechos
          <FormGroup mb="20px">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              locale="es"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="detalle"
              id="detalle"
              placeholder="Detalle de los hechos"
              ref={register({
                required: "El Detalle es necesario",
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.detalle && errors.detalle.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="entidades"
              id="entidades"
              placeholder="Entidad(es) comprendida(s)"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.entidades && errors.entidades.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Select
              name="localidad"
              id="localidad"
              hover="2"
              ref={register({
                required: "Porfavor selecciona una localidad",
              })}
            >
              <option value="">Localidad de los Hechos</option>
              <option value="Amazonas">Amazonas</option>
              <option value="Áncash">Áncash</option>
              <option value="Apurímac">Apurímac</option>
              <option value="Arequipa">Arequipa</option>
              <option value="Ayacucho">Ayacucho</option>
              <option value="Cajamarca">Cajamarca</option>
              <option value="Callao (Provincia)">Callao (Provincia)</option>
              <option value="Cuzco">Cuzco</option>
              <option value="Huancavelica">Huancavelica</option>
              <option value="Huánuco">Huánuco</option>
              <option value="Ica">Ica</option>
              <option value="Junín">Junín</option>
              <option value="La Libertad">La Libertad</option>
              <option value="Lambayeque">Lambayeque</option>
              <option value="Lima">Lima</option>
              <option value="Loreto">Loreto</option>
              <option value="Madre de Dios">Madre de Dios</option>
              <option value="Moquegua">Moquegua</option>
              <option value="Pasco">Pasco</option>
              <option value="Piura">Piura</option>
              <option value="Puno">Puno</option>
              <option value="San Martín">San Martín</option>
              <option value="Tacna">Tacna</option>
              <option value="Tumbes">Tumbes</option>
              <option value="Ucayali">Ucayali</option>
            </Select>
            <Error>{errors.localidad && errors.localidad.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="trabajadores"
              id="trabajadores"
              placeholder="Trabajadores comprendidos"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.trabajadores && errors.trabajadores.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="relacion"
              id="ralacion"
              placeholder="Relación Laboral con el denunciado"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.relacion && errors.relacion.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="fuente"
              id="fuente"
              placeholder="Fuente de información o hechos relevantes para investigar"
              ref={register({
                required: "La fuente es necesaria",
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo de 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.fuente && errors.fuente.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="pruebassustentatorias"
              id="pruebassustentatorias"
              placeholder="Pruebas Sustentatorias"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>
              {errors.pruebassustentatorias &&
                errors.pruebassustentatorias.message}
            </Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="file"
              name="pruebas[]"
              id="pruebas"
              placeholder="Pruebas *"
            />
            <Error>{errors.pruebas && errors.pruebas.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" disabled={serverState.submitting}>
            Enviar
          </Button>
          {serverState.status && (
            <p
              className={`form-output ${
                !serverState.status.ok ? "errorMsg" : "success"
              }`}
            >
              {serverState.status.msg}
            </p>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
