import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import AliadoLogo from "../../../components/ui/aliado-logo"
import SwiperSlider from "../../../components/ui/swiper"
import SectionTitle from "../../../components/ui/section-title"
import Zoom from "react-reveal/Zoom"
import { SectionWrap, AliadoLogoWrap } from "./style"

const AliadosArea = props => {
  const AliadosData = useStaticQuery(graphql`
    {
      allPrismicAliado {
        edges {
          node {
            _previewable
            data {
              imagen {
                gatsbyImageData
              }
              imagen_hover {
                gatsbyImageData
              }
              marca {
                document {
                  ... on PrismicMarca {
                    uid
                  }
                }
              }
              nombre {
                text
              }
            }
          }
        }
      }
    }
  `)
  const { data, isPreview } = useMergePrismicPreviewData(AliadosData)

  const aliados = data.allPrismicAliado.edges

  if (aliados.length % 6 !== 0) {
    for (let i = 0; i < aliados.length % 6; i++) {
      aliados.pop()
    }
  }

  const { slider, sliderStyle } = props
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              title="Aliados"
              subtitle="Socios estratégicos"
              layout="2"
              mb="60px"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <SwiperSlider settings={slider} {...sliderStyle}>
              {aliados &&
                aliados.map((aliado, i) => {
                  return (
                    <AliadoLogoWrap key={`aliadowrap-${i}`}>
                      <Zoom>
                        <AliadoLogo aliado={aliado.node} key={`aliado-${i}`} />
                      </Zoom>
                    </AliadoLogoWrap>
                  )
                })}
            </SwiperSlider>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

AliadosArea.propTypes = {
  slider: PropTypes.object,
  sliderStyle: PropTypes.object,
}

AliadosArea.defaultProps = {
  slider: {
    slidesPerView: 6,
    loop: false,
    speed: 1000,
    pagination: false,
    autoplay: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      575: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 4,
      },
      991: {
        slidesPerView: 5,
      },
      1499: {
        slidesPerView: 6,
      },
    },
  },
  sliderStyle: {
    align: "center",
  },
}

export default AliadosArea
