import React from "react"
import { LocationBtnWrap, LocationBtnText } from "./location-button.style"

const LocationButton = props => {
  return (
    <LocationBtnWrap {...props}>
      <a
        href="http://m.me/mercantilsa"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LocationBtnText type="button">
          <span className="button-icon">
            <i className="icon fab fa-facebook-messenger"></i>
          </span>
          <span className="button-text">Escríbenos por Facebook</span>
        </LocationBtnText>
      </a>
    </LocationBtnWrap>
  )
}

export default LocationButton
