import React, { useState, useEffect, useCallback } from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import YouTube from "react-youtube"
import Heading from "../../../components/ui/heading"
import SectionTitle from "../../../components/ui/section-title"
import { SectionWrap, VideoWrapper, IframeWrapper } from "./redes-area.style"

const RedesArea = () => {
  const [videos, setVideos] = useState([])

  const fetchMyAPI = useCallback(async () => {
    let response = await fetch(
      "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCASSqq-FjTv7RJaNIHaW_Cg&maxResults=2&order=date&type=video&key=AIzaSyA1XXaN16dj3WBck2RY7PFsNPfdpE1R_Vk"
    )
    response = await response.json()
    setVideos(response.items)
  }, [])

  useEffect(() => {
    fetchMyAPI()
  }, [fetchMyAPI])

  const opts = {
    height: "390",
    width: "640",
  }

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle="Manténte informado de nuestras novedades y noticias"
              title="Redes Sociales"
              mb="60px"
              layout="2"
            />
          </Col>
        </Row>
        <Row align="center">
          <Col lg={6}>
            <Heading as="h4" mb="30px">
              Facebook
            </Heading>
            <IframeWrapper>
              <iframe
                title="facebook"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmercantilsa&tabs=timeline&width=426&height=580&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=263527246992700"
                width="426"
                height="580"
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"
              ></iframe>
            </IframeWrapper>
          </Col>
          <Col lg={6}>
            <Heading as="h4" mb="30px">
              Youtube
            </Heading>
            {videos &&
              videos.map(video => (
                <Col key={video.etag}>
                  <VideoWrapper>
                    <YouTube
                      videoId={video.id.videoId}
                      opts={opts}
                      containerClassName={"youtubeContainer"}
                    />
                  </VideoWrapper>
                </Col>
              ))}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default RedesArea
