import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Button from "../../ui/button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Zoom from "react-reveal/Zoom"
import ProductoFallback from "../../../assets/images/producto-fallback340.jpg"
import {
  BoxLargeImgWrap,
  BoxLargeImgInner,
  BoxLargeImgMedia,
  BoxLargeImgLink,
  BoxLargeImgContent,
  HeadingWrap,
  CategoryWrap,
  SubcategoryWrap,
  TextWrap,
  BoxLargeImgBtnWrap,
} from "./box-large-image.style"

const BoxLargeImage = ({
  imageSrc,
  title,
  subcategory,
  category,
  categoryFoto,
  desc,
  path,
  btnText,
  ...boxStyles
}) => {
  const { boxStyle, contentBoxStyle, descStyle, btnStyle } = boxStyles
  let boxImage
  if (imageSrc.gatsbyImageData) {
    boxImage = <GatsbyImage image={getImage(imageSrc)} alt={title} />
  } else {
    boxImage = <img src={ProductoFallback} alt={title} />
  }
  return (
    <BoxLargeImgWrap {...boxStyle}>
      <BoxLargeImgInner>
        <BoxLargeImgMedia>
          <Zoom>{boxImage}</Zoom>
        </BoxLargeImgMedia>
        <BoxLargeImgLink path={path}>{title}</BoxLargeImgLink>
        <BoxLargeImgContent {...contentBoxStyle}>
          <a href={path}>
            <CategoryWrap>{category}</CategoryWrap>
            <SubcategoryWrap>
              {subcategory ? subcategory : <br />}
            </SubcategoryWrap>
            <HeadingWrap as="h6">{title}</HeadingWrap>
          </a>
          {desc && <TextWrap {...descStyle}>{parse(desc)}</TextWrap>}
          <BoxLargeImgBtnWrap>
            <Button
              {...btnStyle}
              className="button"
              to={path}
              icon="far fa-long-arrow-right"
            >
              {btnText}
            </Button>
          </BoxLargeImgBtnWrap>
        </BoxLargeImgContent>
      </BoxLargeImgInner>
    </BoxLargeImgWrap>
  )
}

BoxLargeImage.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  desc: PropTypes.string,
  path: PropTypes.string,
  btnText: PropTypes.string,
  headingStyle: PropTypes.object,
  descStyle: PropTypes.object,
  btnStyle: PropTypes.object,
}

BoxLargeImage.defaultProps = {
  path: "/",
  btnText: "Ver Más",
  btnStyle: {
    iconposition: "right",
    icondistance: "4px",
    varient: "texted",
    hover: "false",
    display: "flex",
    justify: "center",
    alignitems: "center",
    height: "100%",
  },
}

export default BoxLargeImage
