import React from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import PostulanteForm from "../../../components/forms/postulante-form"
import { LineaFormWrapper } from "./linea-form-area.style"

const PostulanteFormArea = ({ headingStyle, textStyle }) => {
  return (
    <LineaFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col>
            <PostulanteForm />
          </Col>
        </Row>
      </Container>
    </LineaFormWrapper>
  )
}

export default PostulanteFormArea
