import React from "react"

import { LinkWrapper } from "./sticky-whatsapp.style"

const StickyWhatsApp = ({ link, ...props }) => {
  const WhatsappLink =
    link ||
    "https://api.whatsapp.com/send?phone=51981346913&text=Buenos%20d%C3%ADas,%20me%20interesan%20los%20productos%20de%20Mercantil%20S.A."

  return (
    <LinkWrapper href={WhatsappLink} {...props}>
      <i className="fab fa-whatsapp whatsapp-icon"></i>
    </LinkWrapper>
  )
}

export default StickyWhatsApp
