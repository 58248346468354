import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { device } from "../../theme"

export const BreadcrumbWrap = styled.div``

export const PageHeaderMixin = css`
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;
  .breadcrumb {
    position: relative;
    z-index: 2;
    color: #fff;
    display: inline-block;
    padding: 0;
    padding-left: 6px;
    margin-bottom: 0;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1.58;
    text-transform: uppercase;
    background-color: transparent;
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      &__item {
        display: inline-block;
      }
    }
    &__link {
      position: relative;
      &:after {
      }
      &:hover {
        color: #d9dbdc;
        &:after {
          width: 100%;
          left: 0;
          right: auto;
          z-index: 0;
        }
      }
      &__active {
        position: relative;
        color: #d9dbdc;
        &:after {
          display: none;
          text-decoration: underline;
        }
      }
    }
    &__title {
      color: #fff;
      text-shadow: 6px 0 2px rgba(0, 0, 0, 0.2), -6px 0 2px rgba(0, 0, 0, 0.2),
        0 6px 2px rgba(0, 0, 0, 0.2), 0 -6px 2px rgba(0, 0, 0, 0.2),
        4px 4px rgba(0, 0, 0, 0.2), -4px -4px 0 rgba(0, 0, 0, 0.2),
        4px -4px 0 rgba(0, 0, 0, 0.2), -4px 4px 0 rgba(0, 0, 0, 0.2);
      font-size: 56px;
      font-weight: 700;
      display: block;
      margin-bottom: 20px;
      @media ${device.large} {
        font-size: 46px;
        line-height: 1.4;
      }
      @media ${device.medium} {
        font-size: 40px;
      }
      @media ${device.small} {
        font-size: 34px;
      }
    }
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: -1;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  ${PageHeaderMixin}
`

export const PageHeaderWrap = styled(BackgroundImage)`
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
  background-size: cover;
  @media ${device.large} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  ${PageHeaderMixin}
`
