import styled from "styled-components"
import { device } from "../../../theme"

export const SectionWrap = styled.section`
  background-color: ${props => props.theme.colors.borderDash};
  padding-top: 90px;
  padding-bottom: 100px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  @media ${device.medium} {
    padding-top: 70px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 50px;
    padding-bottom: 60px;
  }
`

export const DivisionWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 0;
  margin-bottom: 60px;
  border-radius: 4px;
`

export const ProductosWrapper = styled.div``

export const VerMasWrapper = styled.span`
  color: ${props => props.theme.colors.themeColor};
  font-size: 18px;
  font-weight: 500;
`
