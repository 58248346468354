import icon1 from "../../../assets/images/icons/calidad.png"
import icon2 from "../../../assets/images/icons/seguridad-e-higiene.png"
import icon3 from "../../../assets/images/icons/medio-ambiente.png"
import icon4 from "../../../assets/images/icons/icon-certificado.png"

export const data = [
  {
    id: "box-icon-3-1",
    title: "Calidad",
    desc: "Mercantil S.A. será reconocida por proveer productos y servicios de calidad, que satisfagan las necesidades de sus clientes mediante una política de liderazgo y mejoramiento continuo en todos sus procesos",
    path: "/",
    icon: icon1,
  },
  {
    id: "box-icon-3-2",
    title: "Seguridad e Higiene",
    desc: "Mercantil S.A. mantendrá una actitud dinámica en el mejoramiento de sus procesos. Buscando satisfacer las necesidades de sus clientes, proveedores, empleados y la comunidad en general, en lo referente a la salud e higiene.",
    path: "/",
    icon: icon2,
  },
  {
    id: "box-icon-3-3",
    title: "Medio Ambiente",
    desc: "Mercantil S.A., reconoce su responsabilidad con el Medio Ambiente y se compromete a cumplir con las Normas Legales Vigentes. Mejorar sus procesos permanentemente, orientándose a prevenir y/o reducir los impactos ambientales. revisando y estableciendo metas y objetivos periódicamente. Capacitar a su personal en prácticas ambientales.",
    path: "/",
    icon: icon3,
  },
  {
    id: "box-icon-3-4",
    title: "Política Antisoborno",
    desc: "Mercantil S.A. es una empresa dedicada a la comercialización de insumos químicos para diversas industrias, así como a la venta de equipos y materiales de laboratorio. Mercantil S.A. Se encuentra comprometida a trabajar con integridad y honestidad para garantizar una conducta ética según los principios establecidos por la empresa. Se establece una tolerancia cero frente a prácticas o conductas que son o puedan entenderse como soborno tanto a entidades estatales, como a empresas privadas, las cuales permitan a la empresa o a nuestros socios de negocio obtener una ventaja indebida. Esta política es aplicable a los colaboradores de la organización y a sus socios de negocio. Mercantil S.A., a través de los canales correspondientes, atiende las inquietudes presentadas oportunamente y promueve el reporte de buena fe de los eventos que resulten pertinentes al Sistema de Gestión Antisoborno (SGAS), garantizando que no existirán represalias frente a los reportes efectuados. Mercantil S.A. se compromete a mejorar continuamente su Sistema de Gestión Antisoborno (SGAS), el cual forma parte de nuestro Sistema Integrado de Gestión. El incumplimiento de la presente política, genera repercusiones de índole legal, operativas, económicas y de imagen, que pueden poner en riesgo la continuidad de las actividades desarrolladas.",
    path: "/",
    icon: icon4,
  },
]
