import styled, { css } from "styled-components"
import { device } from "../../theme"

export const SingleProductoWrap = styled.div`
  .post-content {
    margin-top: 15px;
    blockquote {
      margin: 54px 65px 44px 70px;
      padding: 0 0 0 20px;
      font-size: 18px;
      line-height: 1.78;
      font-weight: 600;
      border-left: 4px solid #eee;
      border-left-color: #d2a98e;
      position: relative;
      color: #333;
      @media ${device.small} {
        font-size: 16px;
        margin: 50px 70px 44px;
      }
      @media ${device.xsmall} {
        margin: 40px 30px;
      }
    }
  }
`

export const ProductoMedia = styled.div`
  margin-bottom: 46px;
`

export const Thumbnail = styled.div`
  img {
    border-radius: 5px;
  }
`

export const ProductoVideo = styled.div`
  margin: 60px 0;
`

export const Blockquote = styled.blockquote`
  position: relative;
  text-align: center;
  padding: 48px 40px;
  background: ${props => props.theme.colors.themeColor};
  @media ${device.small} {
    padding: 38px 30px;
  }
  .quote-icon {
    color: #fff;
    font-size: 26px;
    margin-bottom: 35px;
  }
  h3 {
    margin-bottom: 26px;
    color: #fff;
    font-size: 24px;
    line-height: 1.375;
    @media ${device.small} {
      font-size: 20px;
    }
  }
  footer {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    @media ${device.small} {
      font-size: 16px;
    }
  }
`

export const ProductoInfo = styled.header``

export const HeaderMetaWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const FooterMetaWrap = styled.div`
  margin: 60px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`

export const SocialShareWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SocialMediaWrap = styled.div`
  position: relative;
  margin-left: 10px;
`

export const FloatingSocialBox = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0%;
  transform: translate(0%, -1px);
  width: auto;
  white-space: nowrap;
  padding: 0 4px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
  user-select: none;
  filter: drop-shadow(0 2px 20px rgba(0, 0, 0, 0.06));
  z-index: 999;
  padding: 10px 15px;
  visibility: hidden;
  opacity: 0;
  transition: ${props => props.theme.transition};
  ${props =>
    props.isOpen &&
    css`
      transform: translate(0%, -12px);
      visibility: visible;
      opacity: 1;
    `}
  @media ${device.small} {
    right: 10%;
  }
  &:before {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(100%);
    content: "";
    border-top: 8px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
  }
`

export const ProductoNavigation = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media ${device.small} {
    margin-top: 16px;
  }
  .post-nav {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    @media ${device.small} {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: 20px;
    }
  }
`
export const InfoTableList = styled.ul`
  margin: 29px;
  max-width: 270px;
  text-align: left;
  li {
    position: relative;
    display: block;
    padding: 7px 0 7px 0;
    svg,
    i {
      color: ${props => props.theme.colors.secondary};
      font-size: 14px;
      margin-right: 10px;
    }
  }
`

export const CarritoControls = styled.div`
  display: flex;
`

export const InputWrapper = styled.div`
  width: 80px;
  margin-right: 15px;
`

export const ButtonWrapper = styled.div``

export const RegresarBtn = styled.div`
  margin-top: 13px;
`
