import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BrandLogoWrap } from "./aliado-logo.style"

const AliadoLogo = ({ aliado, ...props }) => {
  const { imagen, imagen_hover, nombre, marca } = aliado.data
  const path = marca.document ? `marca/${marca.document.uid}` : "#"
  const brandImg = <GatsbyImage image={getImage(imagen)} alt={nombre.text} />
  const hoverImg = (
    <GatsbyImage image={getImage(imagen_hover)} alt={nombre.text} />
  )

  return (
    <BrandLogoWrap {...props}>
      <a href={path}>
        <div className="brand-logo__image">{brandImg}</div>
        {(props.layout === 1 || props.layout === 2) && (
          <div className="brand-logo__image-hover">{hoverImg}</div>
        )}
      </a>
    </BrandLogoWrap>
  )
}

AliadoLogo.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hoverImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

AliadoLogo.defaultProps = {
  layout: 1,
  path: "/",
  title: "Brand Logo",
}

export default AliadoLogo
