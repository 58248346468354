import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import SectionTitle from "../../../components/ui/section-title"
import Text from "../../../components/ui/text"
import ProductoBox from "../../../components/box-large-image/layout-two"
import SwiperSlider from "../../../components/ui/swiper"
import { SectionWrap } from "./productos-area.style"

const CaseStudyArea = ({
  sectionTitleStyle,
  bottomTextStyle,
  productoStyles,
  slider,
  sliderStyle,
}) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "chemistry-icons-bg.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      allPrismicProducto(filter: { data: { es_destacado: { eq: true } } }) {
        edges {
          node {
            uid
            id
            data {
              division {
                document {
                  ... on PrismicDivision {
                    uid
                  }
                }
              }
              categoria {
                document {
                  ... on PrismicCategoria {
                    uid
                    data {
                      nombre {
                        text
                      }
                    }
                  }
                }
              }
              subcategoria {
                document {
                  ... on PrismicSubcategoria {
                    uid
                    data {
                      nombre {
                        text
                      }
                    }
                  }
                }
              }
              descripcion {
                text
              }
              nombre {
                text
              }
              foto {
                gatsbyImageData(width: 340, height: 340)
              }
            }
          }
        }
      }
    }
  `)
  const bgImage = convertToBgImage(getImage(data.file))
  const productos = data.allPrismicProducto.edges
  return (
    <SectionWrap {...bgImage}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              {...sectionTitleStyle}
              title="Productos Destacados"
              subtitle="Insumos químicos y atención de la más alta calidad"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <SwiperSlider {...sliderStyle} settings={slider}>
              {productos.map(producto => (
                <div key={producto.node.id} className="item">
                  <ProductoBox
                    key={producto.node.uid}
                    {...productoStyles}
                    imageSrc={producto.node.data.foto}
                    title={producto.node.data.nombre.text}
                    subcategory={
                      producto.node.data.subcategoria.document &&
                      producto.node.data.subcategoria.document.data.nombre.text
                    }
                    category={
                      producto.node.data.categoria.document &&
                      producto.node.data.categoria.document.data.nombre.text
                    }
                    desc={producto.node.data.descripcion.html}
                    path={
                      producto.node.data.subcategoria.document
                        ? `${producto.node.data.division.document.uid}/${producto.node.data.categoria.document.uid}/${producto.node.data.subcategoria.document.uid}/${producto.node.uid}`
                        : `${producto.node.data.division.document.uid}/${producto.node.data.categoria.document.uid}/${producto.node.uid}`
                    }
                    btnText="Ver más"
                  />
                </div>
              ))}
            </SwiperSlider>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Text {...bottomTextStyle}>
              Te brindamos soporte técnico de alto nivel y excelente servicio
              postventa
            </Text>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

CaseStudyArea.propTypes = {
  sectionStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
  bottomTextStyle: PropTypes.object,
  bottoTextLinkStyle: PropTypes.object,
}

CaseStudyArea.defaultProps = {
  sectionTitleStyle: {
    layout: "2",
    mb: "40px",
    responsive: {
      small: {
        mb: "27px",
      },
    },
  },
  bottomTextStyle: {
    fontSize: "18px",
    fontweight: 500,
    lineHeight: 1.4,
    color: "#333333",
    mt: "60px",
    align: "center",
    responsive: {
      small: {
        mt: "45px",
      },
    },
  },
  bottoTextLinkStyle: {
    fontWeight: 500,
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
  slider: {
    slidesPerView: 3,
    loop: true,
    autoplay: true,
    delay: 500,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  },
  sliderStyle: {
    pagination: {
      bottom: 0,
    },
  },
  productoStyles: {
    boxStyle: {
      mt: "20px",
      mb: "35px",
      ml: "15px",
      mr: "15px",
    },
    contentBoxStyle: {
      pt: "25px",
      pl: "26px",
      pr: "26px",
      textalign: "left",
    },
    headingStyle: {
      as: "h6",
      fontweight: 600,
      mb: "2px",
    },
    descStyle: {
      mb: 0,
      mt: "13px",
    },
  },
}

export default CaseStudyArea
