import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import YouTube from "react-youtube"
import Section, { Row, Col } from "../../../components/ui/wrapper"
import SectionTitle from "../../../components/ui/section-title"
import { VideoWrapper, VideoTitle } from "./videos-de-youtube.style"

const VideosDeYoutube = props => {
  const [videos, setVideos] = useState([])

  const fetchMyAPI = useCallback(async () => {
    let response = await fetch(
      "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCASSqq-FjTv7RJaNIHaW_Cg&maxResults=10&order=date&type=video&key=AIzaSyA1XXaN16dj3WBck2RY7PFsNPfdpE1R_Vk"
    )
    response = await response.json()
    setVideos(response.items)
  }, [])

  useEffect(() => {
    fetchMyAPI()
  }, [fetchMyAPI])

  const opts = {
    height: "240",
    width: "426",
  }
  const { sectionStyle } = props
  return (
    <Section {...sectionStyle}>
      <Row mb="30px">
        <Col>
          <SectionTitle
            title="Tutoriales"
            subtitle="Conoce las presentaciones y uso de nuestros productos"
            layout="2"
          />
        </Col>
      </Row>
      <Row>
        {videos &&
          videos.map(video => (
            <Col lg={6} md={12} key={video.etag}>
              <VideoWrapper>
                <VideoTitle>
                  <a
                    href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {video.snippet.title}
                  </a>
                </VideoTitle>
                <YouTube
                  videoId={video.id.videoId}
                  opts={opts}
                  containerClassName={"youtubeContainer"}
                />
              </VideoWrapper>
            </Col>
          ))}
      </Row>
    </Section>
  )
}

VideosDeYoutube.propTypes = {
  sectionStyle: PropTypes.object,
  caseStudyStyles: PropTypes.object,
}

VideosDeYoutube.defaultProps = {
  sectionStyle: {
    pt: "89px",
    pb: "70px",
    responsive: {
      medium: {
        pt: "72px",
        pb: "50px",
      },
      small: {
        pt: "53px",
        pb: "30px",
      },
    },
  },
}

export default VideosDeYoutube
