import React from "react"

const MapaArea = () => {
  return (
    <div style={{ height: "500px", width: "100%" }}>
      <iframe
        title="Mapa"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.1761446077135!2d-76.97940944798991!3d-12.10009089138977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c7bca1f73357%3A0xb25d53db3aabae57!2sGRUPO%20MERCANTIL!5e0!3m2!1ses-419!2spe!4v1616859263694!5m2!1ses-419!2spe"
        width="100%"
        height="450"
        frameBorder="0"
        allowFullScreen={false}
        aria-hidden="false"
      ></iframe>
    </div>
  )
}

export default MapaArea
