import React from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Timeline from "../../../components/ui/timeline"
import { histories } from "./data"
import { SectionWrap } from "./style"

const HistoryArea = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Timeline items={histories} />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default HistoryArea
