// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carrito-js": () => import("./../../../src/pages/carrito.js" /* webpackChunkName: "component---src-pages-carrito-js" */),
  "component---src-pages-comite-de-intervencion-js": () => import("./../../../src/pages/comite-de-intervencion.js" /* webpackChunkName: "component---src-pages-comite-de-intervencion-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-expo-mercantil-js": () => import("./../../../src/pages/expo-mercantil.js" /* webpackChunkName: "component---src-pages-expo-mercantil-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-libro-de-reclamaciones-js": () => import("./../../../src/pages/libro-de-reclamaciones.js" /* webpackChunkName: "component---src-pages-libro-de-reclamaciones-js" */),
  "component---src-pages-linea-de-etica-js": () => import("./../../../src/pages/linea-de-etica.js" /* webpackChunkName: "component---src-pages-linea-de-etica-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("./../../../src/pages/trabaja-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-buscar-template-buscar-template-js": () => import("./../../../src/templates/buscar-template/buscar-template.js" /* webpackChunkName: "component---src-templates-buscar-template-buscar-template-js" */),
  "component---src-templates-categoria-template-categoria-template-js": () => import("./../../../src/templates/categoria-template/categoria-template.js" /* webpackChunkName: "component---src-templates-categoria-template-categoria-template-js" */),
  "component---src-templates-division-template-division-template-js": () => import("./../../../src/templates/division-template/division-template.js" /* webpackChunkName: "component---src-templates-division-template-division-template-js" */),
  "component---src-templates-marca-template-marca-template-js": () => import("./../../../src/templates/marca-template/marca-template.js" /* webpackChunkName: "component---src-templates-marca-template-marca-template-js" */),
  "component---src-templates-producto-template-producto-template-js": () => import("./../../../src/templates/producto-template/producto-template.js" /* webpackChunkName: "component---src-templates-producto-template-producto-template-js" */),
  "component---src-templates-productos-list-productos-list-js": () => import("./../../../src/templates/productos-list/productos-list.js" /* webpackChunkName: "component---src-templates-productos-list-productos-list-js" */),
  "component---src-templates-subcategoria-template-subcategoria-template-js": () => import("./../../../src/templates/subcategoria-template/subcategoria-template.js" /* webpackChunkName: "component---src-templates-subcategoria-template-subcategoria-template-js" */)
}

