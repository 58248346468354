import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"

export const NavItem = styled.div`
  a {
    display: block;
  }
`

export const NavInner = styled(BackgroundImage)`
  position: relative;
  min-height: 120px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.5);
    border-radius: 5px;
    opacity: 0.5;
  }
  h6 {
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    margin: 0;
    z-index: 1;
  }
  i {
    position: relative;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    z-index: 1;
  }
  ${props =>
    props.rel === "next" &&
    css`
      padding-right: 20px;
      h6 {
        padding-right: 25px;
        padding-left: 20px;
      }
    `}
  ${props =>
    props.rel === "prev" &&
    css`
      flex-direction: row-reverse;
      padding-left: 20px;
      h6 {
        padding-left: 25px;
        padding-right: 20px;
      }
      .icon {
        transform: rotate(180deg);
      }
    `}
`
