import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import Button from "../../../components/ui/button"
import { SectionWrap } from "./style"

const IntroArea = ({ headingStyle, descStyle, setShowForm, showForm }) => {
  const introData = useStaticQuery(graphql`
    {
      prismicTalento {
        data {
          descripcion {
            text
          }
        }
      }
    }
  `)

  const frase = introData.prismicTalento.data.descripcion.text

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined"

  const toggle = () => {
    setShowForm(!showForm)
    if (isBrowser) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading {...headingStyle}>
              ¿Por qué sumar tu talento a Mercantil?
            </Heading>
            <Text {...descStyle}>{frase}</Text>
          </Col>
        </Row>
        <Row mt="30px">
          <Col lg={12}>
            <Button varient="outlined" onClick={toggle}>
              Postula aquí
            </Button>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

IntroArea.defaultProps = {
  headingStyle: {
    as: "h3",
    mb: "20px",
    child: {
      color: "primary",
    },
  },
  descStyle: {
    maxwidth: "760px",
    m: "auto",
  },
}

export default IntroArea
