import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Button from "../../../components/ui/button"
import SectionTitle from "../../../components/ui/section-title"
import BoxLargeImage from "../../../components/box-large-image/layout-one"
import Bounce from "react-reveal/Bounce"
import { SectionWrap } from "./section.style"

const BoxSection = ({ sectionTitleStyle, linkStyle, sectionBottomStyle }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicDivision(sort: { fields: data___ordern, order: ASC }) {
        edges {
          node {
            data {
              nombre {
                text
              }
              foto {
                gatsbyImageData
              }
              descripcion {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)
  const divisiones = data.allPrismicDivision.edges
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle
              {...sectionTitleStyle}
              title="Contamos con cuatro divisiones"
              subtitle=" soportadas en una gestión eficiente y un equipo especializado"
            />
          </Col>
        </Row>
        <Row className="gutter-xl-30">
          {divisiones.map((division, i) => {
            return (
              <Col
                lg={3}
                md={3}
                className="box-item"
                key={`box-image-${division.node.uid}`}
              >
                <Bounce top duration={i * 1000}>
                  <BoxLargeImage
                    title={division.node.data.nombre.text}
                    desc={division.node.data.descripcion.text}
                    path={`/${division.node.uid}`}
                    imageSrc={division.node.data.foto}
                  />
                </Bounce>
              </Col>
            )
          })}
        </Row>
        <Row {...sectionBottomStyle}>
          <Col lg={12}>
            <Button to="/productos" mr="10px">
              Ver Todos
            </Button>
            <Button to="/contacto" ml="10px" varient="outlined">
              Contáctanos
            </Button>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

BoxSection.propTypes = {
  sectionTitleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  sectionBottomStyle: PropTypes.object,
}

BoxSection.defaultProps = {
  sectionTitleStyle: {
    mb: "30px",
    layout: "2",
    responsive: {
      medium: {
        mb: "17px",
      },
    },
  },
  linkStyle: {
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
  sectionBottomStyle: {
    mt: "40px",
    align: "center",
  },
}

export default BoxSection
