import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import PageHeader from "../../components/pageheader"
import { Container, Row, Col } from "../../components/ui/wrapper"
import CategoriasArea from "../../containers/productos/categorias-area"
import Text from "../../components/ui/text"
import ProductoBox from "../../components/producto/layout-one"
import {
  DivisionTemplateWrapper,
  ProductoBoxWrapper,
  SectionTitle,
} from "./division-template.stc"

const DivisionTemplate = ({ data, pageContext, location, ...restProps }) => {
  const { productoStyles, descStyle } = restProps
  const productos = data.losproductos.edges
  const { uid } = pageContext
  const { banner, nombre, video_de_fondo, descripcion } = data.ladivision.data

  return (
    <Layout location={location}>
      <Seo title="División" />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={`División ${nombre.text}`}
        banner={banner}
        video={video_de_fondo}
      />
      <main className="site-wrapper-reveal">
        <DivisionTemplateWrapper>
          <Container fluid>
            {descripcion && (
              <Row>
                <Col lg={12}>
                  <SectionTitle>
                    <Text {...descStyle}>{descripcion.text}</Text>
                  </SectionTitle>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <CategoriasArea divisionActual={uid} />
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                <ProductoBoxWrapper>
                  <Row>
                    {productos.map(producto => {
                      return (
                        <Col
                          sm={{ span: 6 }}
                          md={{ span: 3 }}
                          mb="60px"
                          key={uid}
                        >
                          <ProductoBox
                            {...productoStyles}
                            content={producto.node}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </ProductoBoxWrapper>
              </Col>
            </Row>
          </Container>
        </DivisionTemplateWrapper>
      </main>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query DivisionPageQuery($uid: String!) {
    ladivision: prismicDivision(uid: { eq: $uid }) {
      _previewable
      data {
        video_de_fondo {
          url
        }
        descripcion {
          text
        }
        nombre {
          text
        }
        banner {
          gatsbyImageData(height: 351, width: 1366, layout: FULL_WIDTH)
        }
      }
    }
    losproductos: allPrismicProducto(
      filter: { data: { division: { uid: { eq: $uid } } } }
      sort: { fields: data___orden, order: ASC }
    ) {
      edges {
        node {
          data {
            categoria {
              document {
                ... on PrismicCategoria {
                  uid
                }
              }
            }
            subcategoria {
              document {
                ... on PrismicSubcategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            division {
              document {
                ... on PrismicDivision {
                  uid
                }
              }
            }
            nombre {
              text
            }
            marca {
              document {
                ... on PrismicMarca {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            foto {
              gatsbyImageData(width: 232, height: 232)
            }
            codigo
          }
          uid
        }
      }
    }
  }
`

DivisionTemplate.propTypes = {
  headingStyle: PropTypes.object,
}

DivisionTemplate.defaultProps = {
  headingStyle: {
    as: "h3",
    mb: "70px",
    textalign: "center",
    child: {
      color: "primary",
    },
    responsive: {
      medium: {
        mb: "50px",
      },
    },
  },
  descStyle: {
    maxwidth: "760px",
    m: "auto",
  },
}

export default withPrismicPreview(DivisionTemplate)
