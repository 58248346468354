import React from "react"
import PropTypes from "prop-types"
import Text from "../ui/text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Fade from "react-reveal/Fade"
import {
  ServicioWrapper,
  ServicioMedia,
  ServicioThumb,
  ServicioInfo,
  ServicioTitle,
  ServicioExcerpt,
} from "./servicio.style"

const ServicioBox = ({ content, ...restProps }) => {
  const { titulo, imagen, descripcion } = content.data
  const { wrapStyle } = restProps
  return (
    <ServicioWrapper {...wrapStyle}>
      <ServicioMedia>
        <ServicioThumb>
          <Fade duration={3000} left>
            <GatsbyImage image={getImage(imagen)} alt={titulo.text} />
          </Fade>
        </ServicioThumb>
      </ServicioMedia>
      <ServicioInfo>
        {titulo && <ServicioTitle>{titulo.text}</ServicioTitle>}
        {descripcion && (
          <ServicioExcerpt>
            <Text>{descripcion.text}</Text>
          </ServicioExcerpt>
        )}
      </ServicioInfo>
    </ServicioWrapper>
  )
}

ServicioBox.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  date: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  excerpt: PropTypes.string,
  format: PropTypes.string,
}

export default ServicioBox
