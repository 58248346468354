import styled from "styled-components"
import { device } from "../../../theme"

export const LineaFormWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 64px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 44px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 25px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    border-radius: 5px;
    color: #696969 !important;
    background-color: #dad9da;
    border-width: 1px;
    border-color: #f8f8f8;
    padding-bottom: 0;
    min-height: 56px;
  }
`

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 45px;
  }
`
