import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import GraciasArea from "../containers/global/gracias-area"

const GraciasPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Cianuro" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Gracias"
        />
        <GraciasArea />
      </main>
      <Footer />
    </Layout>
  )
}

export default GraciasPage
