import foto1 from "../../../assets/images/1980.jpg"
import foto2 from "../../../assets/images/2003.jpg"
import foto3 from "../../../assets/images/2006.jpg"
import foto4 from "../../../assets/images/2012.jpg"
import foto5 from "../../../assets/images/2018.jpg"
import foto6 from "../../../assets/images/2020.jpg"

export const histories = [
  {
    year: "1980",
    image: foto1,
    title: "Primeros años",
    desc: "Nuestra empresa fue fundada en 1980, convirtiéndose rápidamente en un reconocido proveedor de insumos químicos para la industria minera. Esta ventana comercial nos permitió ampliar nuestro portafolio de productos, creando las divisiones de Industria, Aguas y Procesos y Laboratorio, consolidando así a Mercantil como socio comercial en el crecimiento y desarrollo de nuestros clientes.",
  },
  {
    year: "2003",
    image: foto2,
    title: "Ampliamos nuestro portafolio",
    desc: "Esta ventana comercial nos permitió ampliar nuestro portafolio de productos, agregando una nueva división: Industria.",
  },
  {
    year: "2006",
    image: foto3,
    title: "Nace Mercantil Laboratorio",
    desc: "Se crea la empresa Mercantil Laboratorio S.A.C., consolidando así a Mercantil como socio comercial en el crecimiento y desarrollo de nuestros clientes.",
  },
  {
    year: "2012",
    image: foto4,
    title: "Se crea Grupo Mercantil",
    desc: "En 2012 se crea Grupo Mercantil, con la finalidad de agrupar a nuestras empresas en una misma marca.",
  },
  {
    year: "2018",
    image: foto5,
    title: "Se reorganiza Mercantil S.A.",
    desc: "En 2018 Mercantil SA reorganiza las empresas comerciales del Grupo, quedando 4 divisiones para la comercialización de todos sus productos.",
  },
  {
    year: "2020",
    image: foto6,
    title: "Presencia internacional",
    desc: "Con la finalidad de seguir expandiéndonos, se crean Mercantil Chile y Mercantil Ecuador.",
  },
]
