import React from "react"
import PropTypes from "prop-types"
import Zoom from "react-reveal/Zoom"
import {
  BoxImgWrapper,
  BoxImgInner,
  BoxImgMedia,
  BoxImgContent,
  HeadingWrap,
  BoxImgLink,
} from "./box-image.style"

const BoxImage = ({ imageSrc, title, i, headingStyle, path, ...boxStyles }) => {
  const iDelay = i === undefined ? 1 : i
  return (
    <BoxImgWrapper {...boxStyles}>
      <BoxImgInner>
        {imageSrc && (
          <BoxImgMedia>
            <Zoom duration={3000} delay={iDelay * 500}>
              <img src={imageSrc} alt={title} />
            </Zoom>
          </BoxImgMedia>
        )}
        <BoxImgContent>
          {title && (
            <HeadingWrap as="h6" {...headingStyle}>
              {title}
            </HeadingWrap>
          )}
        </BoxImgContent>
        <BoxImgLink to={path}>{title}</BoxImgLink>
      </BoxImgInner>
    </BoxImgWrapper>
  )
}

BoxImage.propTypes = {
  boxCSS: PropTypes.object,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  path: PropTypes.string,
}

BoxImage.defaultProps = {
  path: "/",
}

export default BoxImage
