import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Box } from "../../../components/ui/wrapper"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import Button from "../../../components/ui/button"
import Fade from "react-reveal/Fade"
import { SectionWrap } from "./contact.style"

const CertificadoArea = ({ headingStyle, descStyle, btnStyle }) => {
  const CertificadoData = useStaticQuery(graphql`
    {
      certificado: prismicCertificadooea {
        data {
          titulo {
            text
          }
          pdf {
            url
          }
          descripcion {
            text
          }
        }
      }
      sectionBg: file(relativePath: { eq: "chemistry-icons-2-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 527
            quality: 100
            width: 432
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)
  const bgImage = convertToBgImage(getImage(CertificadoData.sectionBg))
  const { titulo, pdf, descripcion } = CertificadoData.certificado.data
  return (
    <SectionWrap {...bgImage}>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <Box>
              <Heading {...headingStyle}>{titulo.text}</Heading>
              <Text {...descStyle}>{descripcion.text}</Text>
              <Fade duration={3000} left>
                <Button to={pdf.url} {...btnStyle}>
                  Descargar
                </Button>
              </Fade>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

CertificadoArea.propTypes = {
  headingStyle: PropTypes.object,
}

CertificadoArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    fontweight: 600,
    lineHeight: 1.4,
  },
  descStyle: {
    mt: "15px",
    fontSize: "18px",
  },
  btnStyle: {
    mt: "20px",
    minwidth: "230px",
    icon: "far fa-file-pdf",
  },
}

export default CertificadoArea
