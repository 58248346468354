import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import LineaDeEticaArea from "../containers/linea-de-etica/linea-form-area"

const LineaDeEticaPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Linea de Ética" />
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Línea de Ética"
    />
    <main className="site-wrapper-reveal">
      <LineaDeEticaArea />
    </main>
    <Footer />
  </Layout>
)

export default LineaDeEticaPage
