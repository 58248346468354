import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import PageHeader from "../../components/pageheader"
import { Container, Row, Col } from "../../components/ui/wrapper"
import Sidebar from "../../containers/productos/sidebar"
import CategoriasArea from "../../containers/productos/categorias-area"
import ProductoBox from "../../components/producto/layout-one"
import Text from "../../components/ui/text"
import {
  CategoriaTemplateWrapper,
  ProductoBoxWrapper,
  SectionTitle,
} from "./categoria-template.stc"

const CategoriaTemplate = ({ data, pageContext, location, ...restProps }) => {
  const document = data.lacategoria.data
  const { productoStyles, descStyle } = restProps
  const { uid } = pageContext
  const productos = data.losproductos.edges
  const { es_linea, nombre, division, foto, video_de_fondo, descripcion } =
    document

  let haySubcategorias = false
  for (let i = 0; i < productos.length; i++) {
    if (productos[i].node.data.subcategoria.document !== null) {
      haySubcategorias = true
    }
  }
  return (
    <Layout location={location}>
      <Seo title={`${division.document.data.nombre.text} | ${nombre.text}`} />
      <Header />

      <PageHeader
        pageContext={pageContext}
        location={location}
        title={nombre.text}
        banner={foto}
        video={video_de_fondo}
        esLinea={es_linea}
      />
      <main className="site-wrapper-reveal">
        <CategoriaTemplateWrapper>
          <Container fluid>
            {descripcion && (
              <Row>
                <Col lg={12}>
                  <SectionTitle>
                    <Text {...descStyle}>{descripcion.text}</Text>
                  </SectionTitle>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <CategoriasArea
                  divisionActual={division.document.uid}
                  categoriaActiva={uid}
                />
              </Col>
            </Row>
            <Row>
              {haySubcategorias && (
                <Col lg={{ span: 2, order: 1 }} xs={{ span: 12, order: 2 }}>
                  <Sidebar
                    divisionActual={division.document.uid}
                    categoriaActual={uid}
                  />
                </Col>
              )}
              <Col
                lg={
                  haySubcategorias
                    ? { span: 10, order: 2 }
                    : { span: 12, order: 1 }
                }
                xs={{ span: 12, order: 1 }}
              >
                <ProductoBoxWrapper>
                  <Row>
                    {productos.map((producto, i) => {
                      return (
                        <Col
                          sm={{ span: 6 }}
                          md={{ span: 3 }}
                          mb="60px"
                          key={`col-${uid}-${i}`}
                        >
                          <ProductoBox
                            {...productoStyles}
                            key={`box-${uid}-${i}`}
                            content={producto.node}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </ProductoBoxWrapper>
              </Col>
            </Row>
          </Container>
        </CategoriaTemplateWrapper>
      </main>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CategoriaPageQuery($uid: String!) {
    lacategoria: prismicCategoria(uid: { eq: $uid }) {
      _previewable
      data {
        nombre {
          text
        }
        descripcion {
          text
        }
        es_linea
        video_de_fondo {
          url
        }
        division {
          document {
            ... on PrismicDivision {
              uid
              data {
                nombre {
                  text
                }
              }
            }
          }
        }
        foto {
          gatsbyImageData(height: 351, width: 1366, layout: FULL_WIDTH)
        }
      }
    }
    losproductos: allPrismicProducto(
      filter: { data: { categoria: { uid: { eq: $uid } } } }
    ) {
      edges {
        node {
          data {
            categoria {
              document {
                ... on PrismicCategoria {
                  uid
                }
              }
            }
            subcategoria {
              document {
                ... on PrismicSubcategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            division {
              document {
                ... on PrismicDivision {
                  uid
                }
              }
            }
            nombre {
              text
            }
            marca {
              document {
                ... on PrismicMarca {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            foto {
              gatsbyImageData(width: 232, height: 232)
            }
            codigo
          }
          uid
        }
      }
    }
  }
`

CategoriaTemplate.propTypes = {
  headingStyle: PropTypes.object,
}

CategoriaTemplate.defaultProps = {
  headingStyle: {
    as: "h3",
    mb: "70px",
    textalign: "center",
    child: {
      color: "primary",
    },
    responsive: {
      medium: {
        mb: "50px",
      },
    },
  },
  descStyle: {
    maxwidth: "760px",
    m: "auto",
  },
}

export default withPrismicPreview(CategoriaTemplate)
