import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import IntroArea from "../containers/nosotros/intro-area"
import PilaresArea from "../containers/nosotros/pilares-area"
import HistoryArea from "../containers/nosotros/history-area"
import PoliticasArea from "../containers/nosotros/politicas-area"
import CertificacionOeaArea from "../containers/nosotros/certificacion-oea-area"

const NosotrosPage = ({ data, pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Nosotros" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Nosotros"
          banner={data}
        />
        <IntroArea />
        <PilaresArea />
        <HistoryArea />
        <PoliticasArea />
        <CertificacionOeaArea />
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "nosotros-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 351
          quality: 100
          width: 1366
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
        )
      }
    }
  }
`

export default NosotrosPage
