import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import ComiteDeIntervencionArea from "../containers/comite-de-intervenciones/comite-form-area"

const ComiteDeIntervencionPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Comite de Intervención" />
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Comité de Intervención"
    />
    <main className="site-wrapper-reveal">
      <ComiteDeIntervencionArea />
    </main>
    <Footer />
  </Layout>
)

export default ComiteDeIntervencionPage
