import React from "react"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import Seo from "../components/seo"
import ProductosArea from "../containers/carrito/productos-area"
import PasosArea from "../containers/carrito/pasos-area"
import ProductosDestacados from "../containers/index/productos-area"

const Cart = () => {
  return (
    <Layout>
      <Seo title="Carrito" />
      <Header />
      <main className="site-wrapper-reveal">
        <PasosArea />
        <ProductosArea />
        <ProductosDestacados />
      </main>
      <Footer />
    </Layout>
  )
}

export default Cart
