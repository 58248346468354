import React from "react"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../../../components/ui/heading"
import ProductoBox from "../../../components/box-large-image/layout-two"
import SwiperSlider from "../../../components/ui/swiper"
import Button from "../../../components/ui/button"
import Line from "../../../components/ui/divider/line"
import { SectionWrap, DivisionWrapper } from "./todos-los-productos.style"

const TodosLosProductos = ({ productoStyles, slider, sliderStyle }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicDivision(sort: { fields: data___ordern, order: ASC }) {
        edges {
          node {
            data {
              nombre {
                text
              }
            }
            uid
          }
        }
      }
      allPrismicProducto(filter: { data: { es_destacado: { eq: true } } }) {
        edges {
          node {
            uid
            data {
              descripcion {
                text
              }
              nombre {
                text
              }
              foto {
                gatsbyImageData(width: 340, height: 340)
              }
              division {
                uid
              }
              categoria {
                document {
                  ... on PrismicCategoria {
                    uid
                    data {
                      nombre {
                        text
                      }
                    }
                  }
                }
              }
              subcategoria {
                document {
                  ... on PrismicSubcategoria {
                    uid
                    data {
                      nombre {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const divisiones = data.allPrismicDivision.edges
  const productos = data.allPrismicProducto.edges

  const productosxdivision = divisiones.map(division =>
    productos.filter(
      producto => producto.node.data.division.uid === division.node.uid
    )
  )

  return (
    <SectionWrap>
      <Container>
        {divisiones.map((division, i) => (
          <DivisionWrapper>
            <Row>
              <Col lg={12}>
                <Link to={`/${division.node.uid}`}>
                  <Heading as="h3" mb="60px" mt="20px" ml="15px">
                    {division.node.data.nombre.text}
                  </Heading>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <SwiperSlider {...sliderStyle} settings={slider}>
                  {productosxdivision[i].map(producto => (
                    <div className="item" key={producto.node.data.uid}>
                      <ProductoBox
                        {...productoStyles}
                        imageSrc={producto.node.data.foto}
                        title={producto.node.data.nombre.text}
                        category={
                          producto.node.data.categoria.document &&
                          producto.node.data.categoria.document.data.nombre.text
                        }
                        subcategory={
                          producto.node.data.subcategoria.document &&
                          producto.node.data.subcategoria.document.data.nombre
                            .text
                        }
                        desc={producto.node.data.descripcion.html}
                        path={
                          producto.node.data.subcategoria.document
                            ? `${producto.node.data.division.uid}/${producto.node.data.categoria.document.uid}/${producto.node.data.subcategoria.document.uid}/${producto.node.uid}`
                            : `${producto.node.data.division.uid}/${producto.node.data.categoria.document.uid}/${producto.node.uid}`
                        }
                        btnText="Ver más"
                      />
                    </div>
                  ))}
                </SwiperSlider>
                <Line mt="40px" mb="40px" />
                <Button mb="20px" ml="15px" to={`/${division.node.uid}`}>
                  Ver Todos
                </Button>
              </Col>
            </Row>
          </DivisionWrapper>
        ))}
      </Container>
    </SectionWrap>
  )
}

TodosLosProductos.defaultProps = {
  slider: {
    slidesPerView: 3,
    loop: true,
    autoplay: true,
    delay: 500,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  },
  sliderStyle: {
    pagination: {
      bottom: 0,
    },
  },
  productoStyles: {
    boxStyle: {
      mt: "20px",
      mb: "35px",
      ml: "15px",
      mr: "15px",
    },
    contentBoxStyle: {
      pt: "25px",
      pl: "26px",
      pr: "26px",
      textalign: "left",
    },
    headingStyle: {
      as: "h6",
      fontweight: 600,
      mb: "2px",
    },
    descStyle: {
      mb: 0,
      mt: "13px",
    },
  },
}
export default TodosLosProductos
