import styled from "styled-components"
import { device } from "../../../theme"

export const SectionWrap = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  @media ${device.medium} {
    padding-top: "80px";
    padding-bottom: "80px";
  }
  @media ${device.small} {
    padding-top: "60px";
    padding-bottom: "60px";
  }
`
export const VideoWrapper = styled.div`
  .youtubeContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const VideoTitle = styled.h5`
  text-align: left;
  font-size: 22px;
  max-width: 426px;
`

export const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  > *{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
`
