import styled from "styled-components"
import Heading from "../ui/heading"
import { device } from "../../theme"

export const ServicioMedia = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  transition: ${props => props.theme.transition};
`

export const ServicioThumb = styled.figure`
  text-align: center;
  img {
    border-radius: 5px;
    transition: all 0.3s;
  }
`

export const ServicioInfo = styled.div`
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  border-radius: 5px;
  transition: all 0.3s;
  margin: -50px auto 30px;
  padding: 30px;
  width: 80%;
  @media ${device.large} {
    padding: 20px;
  }
  @media ${device.medium} {
    padding: 16px;
    width: 90%;
  }
`

export const ServicioTitle = styled(Heading)`
  color: ${props => props.theme.colors.themeColor};
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
`

export const ServicioExcerpt = styled.div`
  margin-top: 10px;
  color: ${props => props.theme.colors.themeColorBlack};
`

export const ServicioWrapper = styled.div`
  &:hover {
    ${ServicioMedia} {
      transform: translateY(-5px);
    }
  }
`
