import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  useMergePrismicPreviewData,
  withPrismicPreview,
} from "gatsby-plugin-prismic-previews"
import { Row, Col, Container } from "../../../components/ui/wrapper"
import { ServicioBoxWrapper } from "./servicios-area.style"
import ServicioBox from "../../../components/servicio-box"

const ServiciosArea = () => {
  const ServiciosData = useStaticQuery(graphql`
    {
      allPrismicServicio {
        edges {
          node {
            id
            _previewable
            data {
              titulo {
                text
              }
              imagen {
                gatsbyImageData
              }
              descripcion {
                text
              }
            }
          }
        }
      }
    }
  `)
  const { data, isPreview } = useMergePrismicPreviewData(ServiciosData)
  const servicios = data.allPrismicServicio.edges
  return (
    <ServicioBoxWrapper>
      <Container>
        {servicios.map(servicio => {
          return (
            <Row key={`row-${servicio.node.id}`}>
              <Col key={`col-${servicio.node.id}`}>
                <ServicioBox
                  key={`box-${servicio.node.id}`}
                  content={servicio.node}
                />
              </Col>
            </Row>
          )
        })}
      </Container>
    </ServicioBoxWrapper>
  )
}

export default withPrismicPreview(ServiciosArea)
