import styled from "styled-components"
import { device } from "../../../theme"

export const HeroWrapper = styled.section`
  padding-top: 0;
  padding-bottom: 30px;
  * {
    color: #fff;
  }
`

export const HeroTextBox = styled.div`
  position: absolute;
  top: 160px;
  left: 160px;
  max-width: 805px;
  color: #fff;
  width: 100%;
  padding-left: 30px;
  margin-left: auto;
  @media ${device.small} {
    top: 20px;
    left: 0;
  }
`
export const TitleHeading = styled.h1`
  @media ${device.medium} {
    font-size: 22px;
  }
`

export const SubTitleHeading = styled.h6`
  margin-top: 20px;
  font-weight: 500;
  @media ${device.medium} {
    display: none;
  }
`
