import styled from "styled-components"

export const ChecksWrapper = styled.div`
  input {
    width: 10%;
    min-height: 16px;
  }
`
export const SuccessWrap = styled.div`
  font-size: 24px;
`
