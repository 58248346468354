import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import LibroDeReclamacionesArea from "../containers/libro-de-reclamaciones/libro-form-area"

const LibroDeReclamacionesPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Libro de Reclamaciones" />
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Libro de Reclamaciones"
    />
    <main className="site-wrapper-reveal">
      <LibroDeReclamacionesArea />
    </main>
    <Footer />
  </Layout>
)

export default LibroDeReclamacionesPage
