import React from "react"
import Seo from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-three"
import Footer from "../containers/layout/footer/footer-one"
import PageHeader from "../components/pageheader"
import VideosDeYoutube from "../containers/videos/videos-de-youtube"

const VideosPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Videos" />
    <Header />
    <PageHeader pageContext={pageContext} location={location} title="Videos" />
    <main className="site-wrapper-reveal">
      <VideosDeYoutube />
    </main>
    <Footer />
  </Layout>
)

export default VideosPage
