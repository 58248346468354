import * as React from "react"
import "@reach/dialog/styles.css"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"

const PreviewPage = () => {
  return (
    <div>
      <h1>Cargando vista previa...</h1>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
