import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import { CategoriasAreaWrapper } from "./categorias-area.style"
import Button from "../../../components/ui/button"

const CategoriasArea = props => {
  const data = useStaticQuery(graphql`
    {
      allPrismicCategoria(
        sort: { fields: data___numero_de_orden, order: ASC }
      ) {
        edges {
          node {
            data {
              nombre {
                text
              }
              division {
                document {
                  ... on PrismicDivision {
                    uid
                  }
                }
              }
            }
            uid
          }
        }
      }
    }
  `)
  const categorias = data.allPrismicCategoria.edges
  const { categoriaActiva, divisionActual } = props
  const categoriasFiltradas = categorias.filter(
    categoria => categoria.node.data.division.document.uid === divisionActual
  )
  return (
    <CategoriasAreaWrapper>
      <Container mt="30px" mb="90px">
        <Row>
          {categoriasFiltradas.map(({ node: categoria }, i) => (
            <Col key={`col-${i}`} lg={3} align="center" mb="10px">
              <Button
                key={`btn-${i}`}
                shape="bullet"
                minwidth="260px"
                to={`/${categoria.data.division.document.uid}/${categoria.uid}`}
                varient={
                  categoriaActiva === categoria.uid ? "contained" : "outlined"
                }
                fontWeight="700"
              >
                {categoria.data.nombre.text.substring(0, 21)}
                {categoria.data.nombre.text.length > 21 && "..."}
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
    </CategoriasAreaWrapper>
  )
}

export default CategoriasArea
