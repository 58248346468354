import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import ComiteForm from "../../../components/forms/comite-form"
import { ComiteFormWrapper, LeftBox } from "./comite-form-area.style"

const ComiteFormArea = ({ headingStyle, textStyle }) => {
  return (
    <ComiteFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <LeftBox>
              <Heading {...headingStyle}>Registro de Hechos</Heading>
              <Text {...textStyle}>
                Denuncie a través de este formulario cualquier conducta
                irregular, poco ética, inadecuada o sospechas razonables de las
                mismas.
              </Text>
            </LeftBox>
          </Col>
          <Col lg={6}>
            <ComiteForm />
          </Col>
        </Row>
      </Container>
    </ComiteFormWrapper>
  )
}

ComiteFormArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
}

ComiteFormArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    pl: "34px",
    lineheight: 1.67,
    fontweight: 600,
    child: {
      color: "primary",
    },
    layout: "quote",
  },
  textStyle: {
    mt: "15px",
    fontSize: "18px",
    ml: "34px",
    color: "#696969",
  },
}

export default ComiteFormArea
