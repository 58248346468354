import React, { Fragment, useState, useEffect, useRef, useContext } from "react"
import CartContext from "../../../../context/CartContext"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../../components/ui/wrapper"
import Text from "../../../../components/ui/text"
import Anchor from "../../../../components/ui/anchor"
import Logo from "../../../../components/logo"
import { Link } from "gatsby"
import Clickable from "../../../../components/ui/clickable"
import { MainMenu, MobileMenu } from "../../../../components/menu"
import Flyout, {
  FlyoutHeader,
  FlyoutBody,
} from "../../../../components/ui/flyout"
import OffCanvas, {
  OffCanvasHeader,
  OffCanvasBody,
} from "../../../../components/ui/off-canvas"
import SearchForm from "../../../../components/forms/search-form/layout-three"
import CloseButton from "../../../../components/ui/close-button"
import BurgerButton from "../../../../components/ui/burger-button"
import {
  HeaderWrap,
  HeaderTop,
  HeaderBottom,
  FixedHeader,
  FixedHeaderHeight,
  HeaderMain,
  HeaderCol,
  HeaderNavigation,
  HeaderElement,
  HeaderInfoItem,
  NumeroCarrito,
} from "./header.style"

const Header = ({ props, ...styles }) => {
  const headerData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            address
            celular
            fijo
          }
        }
      }
      allPrismicDivision(sort: { fields: data___ordern, order: ASC }) {
        edges {
          node {
            uid
            data {
              nombre {
                text
              }
            }
          }
        }
      }
    }
  `)
  const divisiones = headerData.allPrismicDivision.edges
  let texts = []
  let links = []

  divisiones.forEach(node => {
    links.push(node.node.uid)
    texts.push(node.node.data.nombre.text)
  })

  const { address, fijo, celular } = headerData.site.siteMetadata.contact
  const { cart } = useContext(CartContext)

  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const [offCanvasOpen, setOffcanvasOpen] = useState(false)
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0)
  const [totalHeaderHeight, setTotalHeaderHeight] = useState(0)
  const [sticky, setSticky] = useState(false)
  const headerRef = useRef(null)
  const fixedRef = useRef(null)

  const flyoutHandler = () => {
    setFlyoutOpen(prevState => !prevState)
  }
  const offCanvasHandler = () => {
    setOffcanvasOpen(prevState => !prevState)
  }

  useEffect(() => {
    setFixedHeaderHeight(fixedRef.current.clientHeight)
    setTotalHeaderHeight(headerRef.current.clientHeight)
  }, [fixedHeaderHeight])

  useEffect(() => {
    const scrollHandler = () => {
      let scrollPos = window.scrollY
      if (scrollPos > totalHeaderHeight) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }

    window.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [sticky, totalHeaderHeight])

  const {
    noticeStyle,
    phoneElStyle,
    searchElStyle,
    logoStyle,
    burgerBtnElStyle,
    transparent,
  } = styles
  const menuArr = [
    {
      text: "Inicio",
      link: "/",
    },
    {
      text: "Nosotros",
      link: "/nosotros",
    },
    {
      text: "Productos",
      link: "/productos",
      isSubmenu: true,
      submenu: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    {
      text: "Servicios",
      link: "/servicios",
    },
    {
      text: "Videos",
      link: "/videos",
    },
    {
      text: "Trabaja con nosotros",
      link: "/trabaja-con-nosotros",
    },
    {
      text: "Contacto",
      link: "/contacto",
    },
  ]
  for (let i = 0; i < divisiones.length; i++) {
    menuArr[2]["submenu"][i]["text"] = "División " + texts[i]
    menuArr[2]["submenu"][i]["link"] = "/" + links[i]
  }
  return (
    <Fragment>
      <HeaderWrap ref={headerRef} isSticky={sticky} transparent={transparent}>
        <HeaderTop>
          <Container>
            <Row>
              <Col lg={12}>
                <HeaderMain top>
                  <HeaderCol left>
                    <HeaderElement {...phoneElStyle}>
                      <HeaderInfoItem>
                        <Anchor path={`tel:${fijo}`}>
                          <i className="info-icon fa fa-phone"></i>
                          <Text as="strong">{fijo}</Text>
                        </Anchor>
                      </HeaderInfoItem>
                    </HeaderElement>
                    <HeaderElement {...phoneElStyle}>
                      <HeaderInfoItem>
                        <Anchor path={`tel:${celular}`}>
                          <i className="info-icon fa fa-mobile-alt"></i>
                          <Text as="strong">{celular}</Text>
                        </Anchor>
                      </HeaderInfoItem>
                    </HeaderElement>
                    <HeaderElement>
                      <HeaderInfoItem>
                        <Text>
                          <i className="info-icon fa fa-map-marker-alt"></i>
                          <Text as="span">{address}</Text>
                        </Text>
                      </HeaderInfoItem>
                    </HeaderElement>
                  </HeaderCol>
                  <HeaderCol right>
                    <Text
                      color={transparent ? "#fff" : "textColor"}
                      {...noticeStyle}
                    >
                      <Link to="/carrito">
                        Carrito <i className="fas fa-shopping-cart"></i>
                        {cart && cart.length > 0 && (
                          <NumeroCarrito>
                            {cart.reduce((counter, product) => {
                              return counter + product.qty
                            }, 0)}
                          </NumeroCarrito>
                        )}
                      </Link>
                    </Text>
                  </HeaderCol>
                </HeaderMain>
              </Col>
            </Row>
          </Container>
        </HeaderTop>
        <HeaderBottom>
          <FixedHeader ref={fixedRef} isSticky={sticky}>
            <Container>
              <Row>
                <Col lg={12}>
                  <HeaderMain>
                    <HeaderCol left>
                      <Logo
                        {...logoStyle}
                        darkLogo={!transparent}
                        whiteLogo={transparent && !sticky}
                      />
                    </HeaderCol>
                    <HeaderCol right>
                      <HeaderNavigation>
                        <MainMenu
                          whiteColor={transparent && !sticky}
                          layout={3}
                          alignment="right"
                          menuData={menuArr}
                        />
                      </HeaderNavigation>
                      <HeaderElement {...searchElStyle}>
                        <Clickable
                          className="search-btn"
                          onClick={flyoutHandler}
                        >
                          <i className="fa fa-search"></i>
                        </Clickable>
                      </HeaderElement>
                      <HeaderElement
                        {...burgerBtnElStyle}
                        visibility={{ default: "false", lg: "true" }}
                      >
                        <BurgerButton
                          className="burger-btn"
                          onClick={offCanvasHandler}
                        />
                      </HeaderElement>
                    </HeaderCol>
                  </HeaderMain>
                </Col>
              </Row>
            </Container>
          </FixedHeader>
          <FixedHeaderHeight height={fixedHeaderHeight} />
        </HeaderBottom>
      </HeaderWrap>
      <Flyout isOpen={flyoutOpen}>
        <FlyoutHeader>
          <Container fluid>
            <Row>
              <Col lg={12} textalign="right">
                <CloseButton size="large" onClick={flyoutHandler} />
              </Col>
            </Row>
          </Container>
        </FlyoutHeader>
        <FlyoutBody>
          <SearchForm />
        </FlyoutBody>
      </Flyout>
      <OffCanvas
        scrollable={true}
        isOpen={offCanvasOpen}
        onClick={offCanvasHandler}
      >
        <OffCanvasHeader onClick={offCanvasHandler}>
          <Logo darkLogo align={{ default: "flex-start" }} />
        </OffCanvasHeader>
        <OffCanvasBody>
          <MobileMenu menuData={menuArr} />
        </OffCanvasBody>
      </OffCanvas>
    </Fragment>
  )
}

Header.propTypes = {
  phoneElStyle: PropTypes.object,
}

Header.defaultProps = {
  noticeStyle: {
    fontSize: "14px",
    lineHeight: 1.78,
  },
  logoStyle: {
    pt: "14px",
    pb: "14px",
  },
  phoneElStyle: {
    mr: "20px",
  },
  searchElStyle: {
    pl: "50px",
  },
  burgerBtnElStyle: {
    pl: "25px",
  },
}

export default Header
