import styled from "styled-components"
import Heading from "../../ui/heading"

export const BoxLargeImgInner = styled.div`
  transition: ${props => props.theme.transition};
  background-color: ${props => props.theme.colors.spring};
  display: flex;
  min-height: 220px;
`

export const BoxLargeImgMedia = styled.div`
  margin: auto 20px;
  min-width: 150px;
  border-radius: 5px;
  text-align: center;
  img {
    width: 100%;
    transition: transform 1s;
  }
`

export const BoxLargeImgHeading = styled.div`
  margin: auto;
  transition: ${props => props.theme.transition};
  flex-basis: 50%;
  flex-grow: 0;
`

export const HeadingWrap = styled(Heading)`
  line-height: ${props => props.lineHeight || 1.5};
  font-weight: ${props => props.fontweight || 500};
  font-size: 18px;
`

export const BoxLargeImgWrap = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  &:hover {
    ${BoxLargeImgInner} {
      transform: translateY(-5px);
    }
  }
`
