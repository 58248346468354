import React from "react"
import PropTypes from "prop-types"
import icon1 from "../../../assets/images/icons/icon-check.png"
import {
  BoxIconWrap,
  BoxIconInner,
  BoxIconTop,
  IconWrap,
  Heading,
  BoxIconBottom,
  Text,
} from "./box-icon.style"

const BoxIcon = ({ title, desc }) => {
  return (
    <BoxIconWrap>
      <IconWrap>
        <img src={icon1} alt="Check" />
      </IconWrap>
      <BoxIconInner>
        <BoxIconTop>{title && <Heading>{title}</Heading>}</BoxIconTop>
        {desc && (
          <BoxIconBottom>
            <Text>{desc}</Text>
          </BoxIconBottom>
        )}
      </BoxIconInner>
    </BoxIconWrap>
  )
}

BoxIcon.defaultProps = {
  title: "",
  desc: "",
  icon: {},
}

BoxIcon.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
}

export default BoxIcon
