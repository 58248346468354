import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import BoxIcon from "../../../components/box-icon/layout-five"
import { Row, Col } from "../../../components/ui/wrapper"
import { PilaresWrapper } from "./style"

const PilaresArea = () => {
  const pilaresData = useStaticQuery(graphql`
    {
      prismicNosotros {
        data {
          pilares {
            titulo {
              text
            }
            desc {
              text
            }
          }
          pilares_foto {
            gatsbyImageData
          }
          pilares_subtitulo {
            text
          }
          pilares_titulo {
            text
          }
        }
      }
    }
  `)

  const { pilares, pilares_foto, pilares_subtitulo, pilares_titulo } =
    pilaresData.prismicNosotros.data

  return (
    <PilaresWrapper>
      <Row noGutter={true}>
        <Col lg={6}>
          <GatsbyImage image={pilares_foto.gatsbyImageData} />
        </Col>
        <Col lg={6}>
          <Heading as="h4" textalign="center" color="primary" mt="30px">
            {pilares_titulo && pilares_titulo.text}
          </Heading>
          <Text fontSize="18px" align="center">
            {pilares_subtitulo && pilares_subtitulo.text}
          </Text>
          {pilares &&
            pilares.map((pilar, i) => (
              <BoxIcon title={pilar.titulo.text} desc={pilar.desc.text} />
            ))}
        </Col>
      </Row>
    </PilaresWrapper>
  )
}

export default PilaresArea
