import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { List, ListItem, ListLink } from "./subcategorias-list.style"

const SubcategoriasList = ({
  divisionActual,
  categoriaActual,
  subcategoriaActiva,
}) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicSubcategoria(
        sort: { fields: data___numero_de_orden, order: ASC }
      ) {
        edges {
          node {
            data {
              nombre {
                text
              }
              categoria {
                document {
                  ... on PrismicCategoria {
                    uid
                    data {
                      division {
                        uid
                      }
                    }
                  }
                }
              }
            }
            uid
          }
        }
      }
    }
  `)
  const subcategorias = data.allPrismicSubcategoria.edges
  const subcategoriasFiltradas = subcategorias
    .filter(
      categoria =>
        categoria.node.data.categoria.document.uid === categoriaActual
    )
    .filter(
      subcategoria =>
        subcategoria.node.data.categoria.document.data.division.uid ===
        divisionActual
    )
  return (
    <List>
      {subcategorias &&
        subcategoriasFiltradas.map((subcategoria, i) => (
          <ListItem key={`${subcategoria.node.uid}-${i}`}>
            <ListLink
              active={
                subcategoria.node.uid === subcategoriaActiva ? true : false
              }
              path={`/${subcategoria.node.data.categoria.document.data.division.uid}/${subcategoria.node.data.categoria.document.uid}/${subcategoria.node.uid}`}
            >
              <i className="icon icon-1 far fa-long-arrow-right"></i>
              <i className="icon icon-2 far fa-long-arrow-right"></i>
              <span>{subcategoria.node.data.nombre.text}</span>
            </ListLink>
          </ListItem>
        ))}
    </List>
  )
}

export default SubcategoriasList
