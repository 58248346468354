import React from "react"
import PropTypes from "prop-types"
import SubcategoriasList from "../subcategorias-list"
import {
  SidebarWrap,
  RecentPostListWrap,
  RecentWidgetBox,
} from "./sidebar.style"

const Sidebar = props => {
  const { divisionActual, categoriaActual, subcategoriaActiva } = props
  return (
    <SidebarWrap>
      <RecentWidgetBox>
        <RecentPostListWrap>
          <SubcategoriasList
            divisionActual={divisionActual}
            categoriaActual={categoriaActual}
            subcategoriaActiva={subcategoriaActiva}
          />
        </RecentPostListWrap>
      </RecentWidgetBox>
    </SidebarWrap>
  )
}

Sidebar.propTypes = {
  widgetTitleStyle: PropTypes.object,
}

Sidebar.defaultProps = {
  widgetTitleStyle: {
    fontSize: "24px",
    fontweight: 500,
    mb: "27px",
  },
}

export default Sidebar
