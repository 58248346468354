import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Row, Col } from "../../ui/wrapper"
import Form, { FormGroup, Input, Textarea, Error } from "../../ui/form"
import Button from "../../ui/button"

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/b7f091ce-a867-43b8-8511-bfa7d56e20ec",
      data: data,
    })
      .then(r => {
        handleServerResponse(true, "Enviado correctamente", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="empresa"
              id="empresa"
              placeholder="Nombre de la empresa"
              ref={register({
                required: "El Nombre de la Empresa es Necesario",
              })}
            />
            <Error>{errors.domicilio && errors.domicilio.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Nombre del Contacto"
              ref={register({ required: "Nombre es Necesario" })}
            />
            <Error>{errors.name && errors.name.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="cargo"
              id="cargo"
              placeholder="Cargo"
              ref={register({ required: "Cargo es Necesario" })}
            />
            <Error>{errors.cargo && errors.cargo.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="telefono"
              id="telefono"
              placeholder="Número de Teléfono"
              ref={register({ required: "Teléfono es Necesario" })}
            />
            <Error>{errors.telefono && errors.telefono.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email *"
              ref={register({
                required: "Email es necesario",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "email inválido",
                },
              })}
            />
            <Error>{errors.email && errors.email.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="comentarios"
              id="comentarios"
              placeholder="Comentarios"
              ref={register({
                maxLength: {
                  value: 5000,
                  message: "Máximo de 5000 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.comentarios && errors.comentarios.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" disabled={serverState.submitting}>
            Enviar
          </Button>
          {serverState.status && (
            <p
              className={`form-output ${
                !serverState.status.ok ? "errorMsg" : "success"
              }`}
            >
              {serverState.status.msg}
            </p>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
