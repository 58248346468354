import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Section, { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Button from "../../../components/ui/button"

const CTASection = ({
  sectionStyle,
  heading,
  text,
  ButtonOne,
  ButtonTwo,
  ...props
}) => {
  const data = useStaticQuery(graphql`
    {
      prismicBrochure {
        data {
          pdf {
            url
          }
          titulo {
            text
          }
        }
      }
    }
  `)
  const { pdf, titulo } = data.prismicBrochure.data
  return (
    <Section {...sectionStyle}>
      <Container>
        <Row className="align-items-center text-lg-left text-center">
          <Col xl={8} lg={7}>
            <Heading {...heading}>{titulo && titulo.text}</Heading>
          </Col>
          <Col xl={4} lg={5} className="text-center">
            <Button
              to={pdf.url}
              {...ButtonOne}
              icon="fas fa-file-pdf"
              iconposition="right"
              icondistance="8px"
              iconsize="16px"
            >
              Descargar
            </Button>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

CTASection.propTypes = {
  heading: PropTypes.object,
}

CTASection.defaultProps = {
  sectionStyle: {
    bgColor: "#3966A8",
    bgposition: "top 35% right -68px",
    bgsize: "auto",
    pt: "80px",
    pb: "80px",
    responsive: {
      medium: {
        pt: "60px",
        pb: "60px",
      },
      small: {
        pt: "40px",
        pb: "40px",
      },
    },
  },
  heading: {
    as: "h3",
    color: "#fff",
    responsive: {
      medium: {
        mb: "10px",
      },
    },
  },
  ButtonOne: {
    skin: "light",
    bgcolor: "#dad9da",
    color: "#002fa6",
    fontWeight: 700,
  },
}

export default CTASection
