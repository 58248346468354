import * as React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// Update the path to your Link Resolver
import { linkResolver } from "../../linkResolver"
import Seo from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from "../../containers/layout/header/header-three"
import Footer from "../../containers/layout/footer/footer-one"
import PageHeader from "../../components/pageheader"
import { Container, Row, Col } from "../../components/ui/wrapper"
import ProductoBox from "../../components/producto/layout-one"
import { MarcaTemplateWrapper, ProductoBoxWrapper } from "./marca-template.stc"

const MarcaTemplate = ({ data, pageContext, location, ...restProps }) => {
  const { productoStyles } = restProps
  const productos = data.losproductos.edges
  const { uid } = pageContext
  const { nombre } = data.lamarca.data
  return (
    <Layout location={location}>
      <Seo title={nombre.text} />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={nombre.text}
      />
      <main className="site-wrapper-reveal">
        <MarcaTemplateWrapper>
          <Container fluid>
            <ProductoBoxWrapper>
              <Row>
                {productos.map(producto => {
                  return (
                    <Col sm={{ span: 6 }} md={{ span: 3 }} mb="60px" key={uid}>
                      <ProductoBox
                        {...productoStyles}
                        content={producto.node}
                      />
                    </Col>
                  )
                })}
              </Row>
            </ProductoBoxWrapper>
          </Container>
        </MarcaTemplateWrapper>
      </main>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query MarcaPageQuery($uid: String!) {
    lamarca: prismicMarca(uid: { eq: $uid }) {
      _previewable
      data {
        nombre {
          text
        }
      }
    }
    losproductos: allPrismicProducto(
      filter: { data: { marca: { uid: { eq: $uid } } } }
      sort: { fields: data___orden, order: ASC }
    ) {
      edges {
        node {
          data {
            division {
              document {
                ... on PrismicDivision {
                  uid
                }
              }
            }
            categoria {
              document {
                ... on PrismicCategoria {
                  uid
                }
              }
            }
            subcategoria {
              document {
                ... on PrismicSubcategoria {
                  uid
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            marca {
              document {
                ... on PrismicMarca {
                  uid
                }
              }
            }
            nombre {
              text
            }
            marca {
              document {
                ... on PrismicMarca {
                  data {
                    nombre {
                      text
                    }
                  }
                }
              }
            }
            foto {
              gatsbyImageData(width: 232, height: 232)
            }
            codigo
          }
          uid
        }
      }
    }
  }
`

export default withPrismicPreview(MarcaTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
