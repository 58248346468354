import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import Heading from "../../../components/ui/heading"
import Text from "../../../components/ui/text"
import ExpoForm from "../../../components/forms/expo-form"
import { ExpoFormWrapper, LeftBox } from "./expo-form-area.style"

const ExpoFormArea = ({ headingStyle, textStyle }) => {
  return (
    <ExpoFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <LeftBox>
              <Heading {...headingStyle}>Inscripción</Heading>
              <Text {...textStyle}></Text>
            </LeftBox>
          </Col>
          <Col lg={6}>
            <ExpoForm />
          </Col>
        </Row>
      </Container>
    </ExpoFormWrapper>
  )
}

ExpoFormArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
}

ExpoFormArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    pl: "34px",
    lineheight: 1.67,
    fontweight: 600,
    child: {
      color: "primary",
    },
    layout: "quote",
  },
  textStyle: {
    mt: "15px",
    fontSize: "18px",
    ml: "34px",
    color: "#696969",
  },
}

export default ExpoFormArea
