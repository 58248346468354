import React from "react"
import Slide from "react-reveal/Slide"

import {
  TimelineWrap,
  TimelineList,
  Line,
  Item,
  Dots,
  TimelineRow,
  TimelineFeature,
  TimelineFeatureInner,
  TimelineInfo,
  TimelineInfoInner,
  DateWrap,
  Photo,
  TimelineContent,
  Title,
  Description,
} from "./style"

const Timeline = ({ items }) => {
  return (
    <TimelineWrap>
      <TimelineList>
        <Line />
        {items &&
          items.map((item, i) => {
            const { year, image, title, desc } = item
            return (
              <Item
                key={`timeline-${i}`}
                align={i % 2 === 0 ? "left" : "right"}
              >
                <Dots />
                <TimelineRow>
                  <TimelineFeature>
                    <TimelineFeatureInner>
                      {year && (
                        <DateWrap>
                          {i % 2 === 0 ? (
                            <Slide duration={3000} left>
                              {year}
                            </Slide>
                          ) : (
                            <Slide duration={3000} right>
                              {year}
                            </Slide>
                          )}
                        </DateWrap>
                      )}
                      {image && (
                        <Photo>
                          <Slide duration={3000} bottom>
                            <img src={image} alt={title} />
                          </Slide>
                        </Photo>
                      )}
                    </TimelineFeatureInner>
                  </TimelineFeature>
                  <TimelineInfo>
                    <TimelineInfoInner>
                      <TimelineContent>
                        {i % 2 === 0 ? (
                          <Slide duration={3000} right>
                            {title && <Title>{title}</Title>}
                            {desc && <Description>{desc}</Description>}
                          </Slide>
                        ) : (
                          <Slide duration={3000} left>
                            {title && <Title>{title}</Title>}
                            {desc && <Description>{desc}</Description>}
                          </Slide>
                        )}
                      </TimelineContent>
                    </TimelineInfoInner>
                  </TimelineInfo>
                </TimelineRow>
              </Item>
            )
          })}
      </TimelineList>
    </TimelineWrap>
  )
}

export default Timeline
