import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import Logo from "../../../../assets/images/logo/light-logo-160x48.png"
import { Row, Col } from "../../../../components/ui/wrapper"
import Text from "../../../../components/ui/text"
import Anchor from "../../../../components/ui/anchor"
import Heading from "../../../../components/ui/heading"
import Social, { SocialLink } from "../../../../components/ui/social"
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterWidgetList,
  FooterBottom,
} from "./footer.style"

const Footer = ({ copyrightStyle, ...props }) => {
  const siteInfo = useStaticQuery(graphql`
    query FooterSiteQuery {
      site {
        siteMetadata {
          copyright
          contact {
            fijo
            celular
            email
            address
            website
          }
          social {
            facebook
            linkedin
          }
        }
      }
      allPrismicDivision(sort: { fields: data___ordern, order: ASC }) {
        edges {
          node {
            uid
            data {
              nombre {
                text
              }
            }
          }
        }
      }
    }
  `)
  const divisiones = siteInfo.allPrismicDivision.edges
  const { fijo, celular, email, address } = siteInfo.site.siteMetadata.contact
  const { copyright } = siteInfo.site.siteMetadata
  const { facebook, linkedin } = siteInfo.site.siteMetadata.social
  return (
    <FooterWrap {...props}>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                <LogoWidget>
                  <img src={Logo} alt="Logo" />
                  <Text mt="10px" color="#FFFFFF">
                    Ruc: 20100312736
                  </Text>
                </LogoWidget>
                <TextWidget>
                  {address && (
                    <Text mb="10px" color="#FFFFFF">
                      {address}
                    </Text>
                  )}
                  {email && (
                    <Text mb="10px">
                      <Anchor
                        path={`mailto:${email}`}
                        color="#FFFFFF"
                        hoverstyle="2"
                      >
                        {email}
                      </Anchor>
                    </Text>
                  )}
                  {celular && (
                    <Text mb="10px">
                      <Anchor
                        path={`tel:${celular}`}
                        fontWeight="800"
                        color="#fff"
                        hoverstyle="2"
                      >
                        {celular}
                      </Anchor>
                    </Text>
                  )}
                  {fijo && (
                    <Text mb="10px">
                      <Anchor
                        path={`tel:${fijo}`}
                        fontWeight="800"
                        color="#fff"
                        hoverstyle="2"
                      >
                        {fijo}
                      </Anchor>
                    </Text>
                  )}
                </TextWidget>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "31px" } }}>
                <Heading as="h6" mt="-3px" mb="20px" color="#FFFFFF">
                  Divisiones
                </Heading>
                <FooterWidgetList>
                  {divisiones.map(division => (
                    <li key={division.node.uid}>
                      <Anchor
                        path={`/${division.node.uid}`}
                        color="#FFFFFF"
                        hoverstyle="2"
                      >
                        {division.node.data.nombre.text}
                      </Anchor>
                    </li>
                  ))}
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget responsive={{ medium: { mb: "27px" } }}>
                <Heading as="h6" mt="-3px" mb="20px" color="#FFFFFF">
                  Mapa de Sitio
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor path="/" color="#FFFFFF" hoverstyle="2">
                      Inicio
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/nosotros" color="#FFFFFF" hoverstyle="2">
                      Nosotros
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/productos" color="#FFFFFF" hoverstyle="2">
                      Productos
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/servicios" color="#FFFFFF" hoverstyle="2">
                      Servicios
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/trabaja-con-nosotros"
                      color="#FFFFFF"
                      hoverstyle="2"
                    >
                      Trabaja con nosotros
                    </Anchor>
                  </li>
                  <li>
                    <Anchor path="/contacto" color="#FFFFFF" hoverstyle="2">
                      Contacto
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/politica-de-privacidad"
                      color="#FFFFFF"
                      hoverstyle="2"
                    >
                      Política de privacidad
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/expo-mercantil"
                      color="#FFFFFF"
                      hoverstyle="2"
                    >
                      Expo Mercantil
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
            <Col lg={3} md={5} sm={7}>
              <FooterWidget>
                <Heading
                  as="h6"
                  mt="-3px"
                  mb="20px"
                  color="#FFFFFF"
                  hoverstyle="2"
                >
                  Canales de Denuncias y Reclamos
                </Heading>
                <FooterWidgetList>
                  <li>
                    <Anchor
                      path="/libro-de-reclamaciones"
                      color="#FFFFFF"
                      hoverstyle="2"
                    >
                      Libro de Reclamaciones
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="/linea-de-etica"
                      color="#FFFFFF"
                      hoverstyle="2"
                    >
                      Línea de Ética
                    </Anchor>
                  </li>
                  <li>
                    <Anchor
                      path="https://mercantil.buk.pe/cul_partner_complaint/tickets/new"
                      color="#FFFFFF"
                      hoverstyle="2"
                    >
                      Línea de atención al colaborador
                    </Anchor>
                  </li>
                </FooterWidgetList>
              </FooterWidget>
            </Col>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              {copyright && (
                <Text {...copyrightStyle}>
                  &copy; Copyright {new Date().getFullYear()} {parse(copyright)}
                </Text>
              )}
            </Col>
            <Col md={6} className="text-center text-md-right">
              <Social space="38px" tooltip={true} shape="rounded">
                {facebook && (
                  <SocialLink path={facebook} title="Facebook" color="#FFFFFF">
                    <i className="fab fa-facebook-f link-icon"></i>
                  </SocialLink>
                )}
                {linkedin && (
                  <SocialLink path={linkedin} title="Linkedin" color="#FFFFFF">
                    <i className="fab fa-linkedin link-icon"></i>
                  </SocialLink>
                )}
              </Social>
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  )
}

Footer.propTypes = {
  bgcolor: PropTypes.string,
  reveal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Footer.defaultProps = {
  bgcolor: "#D4D6D7",
  reveal: "false",
  copyrightStyle: {
    color: "#FFFFFF",
    responsive: {
      small: {
        pb: "15px",
      },
    },
  },
}

export default Footer
