import styled, { css } from "styled-components"
import Heading from "../../ui/heading"
import { device } from "../../../theme"

export const ProductoMedia = styled.div`
  a {
    display: block;
  }
  margin-bottom: 20px;
  transition: ${props => props.theme.transition};
`

export const ProductoThumb = styled.figure`
  min-height: 239px;
  text-align: center;
  @media ${device.large} {
    //min-height: 256px;
  }
  img {
    border-radius: 5px;
    transition: all 0.3s;
  }
`

export const ProductoInfo = styled.div``

export const ProductoHeader = styled.header``

export const ProductoTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 0;
  a {
    color: inherit;
    font-size: inherit;
  }
`

export const ProductoExcerpt = styled.div`
  margin-top: 10px;
`

export const ProductoWrapper = styled.div`
  margin-bottom: ${props => props.mb};
  ${props =>
    props.responsive &&
    css`
      ${props =>
        props.responsive.small &&
        css`
          @media ${device.small} {
            margin-bottom: ${props => props.responsive.small.mb};
          }
        `}
    `}
  &:hover {
    ${ProductoMedia} {
      transform: translateY(-5px);
    }
  }
`
