import React from "react"
import PropTypes from "prop-types"
import SectionTitle from "../../../components/ui/section-title"
import { Container, Row, Col } from "../../../components/ui/wrapper"
import BoxImage from "../../../components/box-image/layout-two"
import Zoom from "react-reveal/Zoom"
import { SectionWrap } from "./section.style"

import image1 from "../../../assets/images/icons/icon-1.png"
import image2 from "../../../assets/images/icons/icon-2.png"
import image3 from "../../../assets/images/icons/icon-3.png"
import image4 from "../../../assets/images/icons/icon-4.png"
import image5 from "../../../assets/images/icons/icon-5.png"
import image6 from "../../../assets/images/icons/icon-6.png"

const BoxSection = ({ headingStyle, linkStyle }) => {
  const boxContents = [
    {
      id: 1,
      imageSrc: image1,
      title: "OEA – Operador Económico Autorizado",
      path: "https://mercantil.cdn.prismic.io/mercantil/251a0c0e-1e31-40fc-8ef4-00abae2cff8d_Foto+Certificado+OEA+.pdf",
    },
    {
      id: 2,
      imageSrc: image2,
      title: "BPA – Buenas Prácticas de Almacenamiento",
      path: "#",
    },
    {
      id: 3,
      imageSrc: image3,
      title: "ICMI – Código Internacional para el Manejo de Cianuro",
      path: "#",
    },
    {
      id: 4,
      imageSrc: image4,
      title: "Registro Achilles (Regic - Siclar)",
      path: "#",
    },
    {
      id: 5,
      imageSrc: image5,
      title: "Homologaciones con Bureau Veritas, HODELPE, MEGA y Kiwa Perú.",
      path: "#",
    },
    {
      id: 6,
      imageSrc: image6,
      title: "Reconocimiento en Responsabilidad Social por ANIQUEM",
      path: "#",
    },
  ]
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle
              layout="2"
              subtitle="Nuestro personal de seguridad cuenta con certificaciones de"
              title="Certificaciones"
            />
          </Col>
        </Row>
        <Row>
          {boxContents.map((boxContent, i) => {
            return (
              <Col
                lg={4}
                md={6}
                className="box-item"
                key={`box-image-${boxContent.id}`}
              >
                <Zoom duration={i * 1000}>
                  <BoxImage
                    imageSrc={boxContent.imageSrc}
                    title={boxContent.title}
                    path={boxContent.path}
                    i={i}
                  />
                </Zoom>
              </Col>
            )
          })}
        </Row>
      </Container>
    </SectionWrap>
  )
}

BoxSection.propTypes = {
  headingStyle: PropTypes.object,
  linkStyle: PropTypes.object,
}

BoxSection.defaultProps = {
  headingStyle: {
    as: "h3",
    fontSize: "18px",
    fontweight: 500,
    lineHeight: 1.4,
    color: "#333333",
    mt: "40px",
    textalign: "center",
  },
  linkStyle: {
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
}

export default BoxSection
